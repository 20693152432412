import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";

function SignUp() {
  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Our LogIn Register --> */}

      <section className="our-log-reg bgc-fa" style={{ paddingTop: "9%" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6 offset-lg-3">
              <div className="sign_up_form inner_page">
                <div className="heading">
                  <h3 className="text-center">Register to start learning</h3>
                  <p className="text-center">
                    Have an account?{" "}
                    <a className="text-thm" href="/login">
                      Login
                    </a>
                  </p>
                </div>
                <div className="details">
                  <form action="#">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName2"
                        placeholder="Username"
                      ></input>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail3"
                        placeholder="Email Address"
                      ></input>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword4"
                        placeholder="Password"
                      ></input>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword5"
                        placeholder="Confirm Password"
                      ></input>
                    </div>
                    <div className="form-group custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="exampleCheck3"
                      ></input>
                      <label
                        className="custom-control-label"
                        for="exampleCheck3"
                      >
                        Want to become an instructor?
                      </label>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-log btn-block btn-thm2"
                    >
                      Register
                    </button>
                    <div className="divide">
                      <span className="lf_divider">Or</span>
                      <hr></hr>
                    </div>
                    <div className="row mt40">
                      <div className="col-lg">
                        <button
                          type="submit"
                          className="btn btn-block color-white bgc-fb mb0"
                        >
                          <i className="fa fa-facebook float-left mt5"></i>{" "}
                          Facebook
                        </button>
                      </div>
                      <div className="col-lg">
                        <button
                          type="submit"
                          className="btn btn2 btn-block color-white bgc-gogle mb0"
                        >
                          <i className="fa fa-google float-left mt5"></i> Google
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </>
  );
}

export default SignUp;
