import { useState, useEffect } from "react";
import { Space, Table, Button, Input, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { axiosPrivate } from "../../api/axios";
import DeleteProject from "./delete-project";

const ListProject = () => {
  const [loadingState, setLoadingState] = useState(true);
  const [projects, setProjects] = useState([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [fetAgain, setfetAgain] = useState(1);

  useEffect(() => {
    async function fetchData() {
      const response = await axiosPrivate.get("/projects");
      const projectsData = response.data.data.map((d) => ({
        ...d,
        key: d.id,
      }));
      setProjects(projectsData);
      setLoadingState(false);
    }
    fetchData();
  }, [fetAgain]);

  const handleOnChange = (e) => {
    // const searchKey = e.target.value;
    // setLoadingState(true);
    // if (e.target.value == "") {
    //   setDataUrl("");
    // } else {
    //   const url = "/items/search?keyWord=" + searchKey;
    //   setDataUrl(url);
    //   setPagination({});
    // }
  };

  const columns = [
    {
      title: "Listing Title",
      dataIndex: "nameSlug",
      key: "nameSlug",
      render: (_, record) => (
        <>
          <th scope="row">
            <div className="feat_property list favorite_page style2">
              <div className="thumb">
                <img
                  className="img-whp"
                  src={record.imageUrls[0]}
                  alt="fp1.jpg"
                ></img>
                <div className="thmb_cntnt">
                  <ul className="tag mb0">
                    <li className="list-inline-item dn"></li>
                    <li className="list-inline-item">
                      <a href="/">Featured</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="details">
                <div className="tc_content">
                  <h4>{record.name}</h4>
                  <p>
                    <span className="flaticon-placeholder"></span>
                    {record.address +
                      ", " +
                      record.city +
                      ", " +
                      record.zipCode}
                  </p>
                  <small>From </small>
                  {(+record.lowPrice).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                  })}
                </div>
              </div>
            </div>
          </th>
        </>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <a href="home">{text}</a>,
    },
    {
      title: "Sales Status",
      dataIndex: "saleStatus",
      key: "saleStatus",
      render: (text) => <a href="home">{text}</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <ul className="view_edit_delete_list mb0">
            <li
              className="list-inline-item"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <a href={`property/edit/${record.id}`}>
                <Button
                  type="link"
                  primary
                  size="large"
                  // onClick={() => {
                  //   setSelectedDeveloper(record);
                  //   setEditModalOpen(true);
                  // }}
                >
                  <span className="flaticon-edit"></span>
                </Button>
              </a>
            </li>
            <li
              className="list-inline-item"
              data-toggle="tooltip"
              data-placement="top"
              title="Delete"
            >
              <Button
                type="link"
                danger
                size="large"
                onClick={() => {
                  setSelectedId(record.id);
                  setDeleteModalOpen(true);
                }}
              >
                <span className="flaticon-garbage"></span>
              </Button>
            </li>
            <li className="list-inline-item" size="large">
              <Button type="link" style={{ color: "green" }}>
                <span className="flaticon-view"></span>
              </Button>
            </li>
          </ul>
        </Space>
      ),
    },
  ];
  return (
    <section className="our-dashbord dashbord bgc-f7 pb50">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-xl-2 dn-992 pl0"></div>
          <div className="col-lg-9 col-xl-10 maxw100flex-992">
            <div className="row">
              <div className="col-xl-12">
                <div className="application_statics">
                  <div style={{ textAlign: "center" }}>
                    <h4>Projects</h4>
                  </div>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Row justify="space-between">
                      <Col>
                        <div classNameName="add_new_dev">
                          <a href="/addproperty">
                            <Button type="primary">Add New Project</Button>
                          </a>
                        </div>
                      </Col>
                      <Col>
                        <div classNameName="search_in_datatable">
                          <Input
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            onChange={(e) => {
                              handleOnChange(e);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Table
                      loading={loadingState}
                      columns={columns}
                      dataSource={projects}
                    />
                    <DeleteProject
                      id={selectedId}
                      open={deleteModalOpen}
                      setOpen={setDeleteModalOpen}
                      setfetAgain={setfetAgain}
                      fetAgain={fetAgain}
                    ></DeleteProject>
                  </Space>
                </div>
              </div>
            </div>
            <div className="row mt50">
              <div className="col-lg-6 offset-lg-3">
                <div className="copyright-widget text-center">
                  <p>© 2020 Find House. Made with love.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ListProject;
