import React, { useEffect } from "react";
import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import "./ten-things.css";
import loadjs from "loadjs";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Select,
  Space,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import TextArea from "antd/es/input/TextArea";
import axios from "../../api/axios";

function BookAUnit() {
  const onFinish = async (values) => {
    console.log("The values are => ");
    console.log(values);

    delete values.confirmEmail;
    axios
      .post("/reservations", values)
      .then((response) => {
        message.success(
          "Thank you for reserving the unit. You will soon hear from us!"
        );
      })
      .catch((error) => {
        message.success(
          "Thank you for subscribing. You will soon hear from us!"
        );
      });
  };
  const onFinishFailed = () => {};

  const onDatePickerChange = () => {};
  useEffect(() => {
    loadjs("./js/script.js", () => {});
  }, []);

  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "9%" }}
      >
        <div class="container">
          <div class="row">
            <div class="main_blog_post_content">
              <div class="mbp_thumb_post">
                <div>
                  <p class="mb30">
                    <div className="col-lg-6 offset-lg-3">
                      <div className="testimonial_grid style2">
                        <h1 style={{ color: "green" }}>RESERVE A UNIT</h1>
                        <b style={{ color: "black", fontSize: "17px" }}>
                          Please fill out the standard worksheet below ensuring
                          you have the correct project name and have completed
                          all of the questions. Developer's will not accept any
                          missing, incomplete or information to follow.
                        </b>
                        <p style={{ color: "black", fontSize: "17px" }}>
                          <b>
                            Contact us if you have any questions 416-303-SOLD
                            (7653)
                          </b>
                        </p>
                      </div>
                    </div>
                    <br></br>
                    <div class="row">
                      <div
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Form
                          layout="vertical"
                          name="basic"
                          style={{
                            width: "800px",
                            borderStyle: "solid",
                            borderColor: "green",
                            padding: "20px",
                          }}
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                        >
                          <Form.Item
                            label=<h4>
                              <b>Condo Project Name</b>
                            </h4>
                            name="projectName"
                            rules={[
                              {
                                required: true,
                                message: "Please input project name!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Purchaser First Name</b>
                            </h4>
                            name="firtsname"
                            rules={[
                              {
                                required: true,
                                message: "Please input your firstname!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Purchaser Last Name</b>
                            </h4>
                            name="lastname"
                            rules={[
                              {
                                required: true,
                                message: "Please input your lastname!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Purchaser Street Address </b>
                            </h4>
                            name="street"
                            rules={[
                              {
                                required: true,
                                message: "Please input address!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>City</b>
                            </h4>
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Please input your city!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Province</b>
                            </h4>
                            name="province"
                            rules={[
                              {
                                required: true,
                                message: "Please input your province!",
                              },
                            ]}
                          >
                            <Select
                              defaultValue="ontario"
                              size="large"
                              options={[
                                { value: "alberta", label: "Alberta" },
                                { value: "bri", label: "British Columbia" },
                                { value: "brun", label: "New Brunswick" },
                                {
                                  value: "new",
                                  label: "Newfoundland and Labrador",
                                },
                                {
                                  value: "north",
                                  label: "Northwest Territories",
                                },
                                {
                                  value: "nova",
                                  label: "Nova Scotia",
                                },
                                {
                                  value: "Nunavut",
                                  label: "Nunavut",
                                },
                                {
                                  value: "ontario",
                                  label: "Ontario",
                                },
                                {
                                  value: "prince",
                                  label: "Prince Edward Island",
                                },
                                {
                                  value: "quebec",
                                  label: "Quebec",
                                },
                                {
                                  value: "saka",
                                  label: "Saskatchewan",
                                },
                                {
                                  value: "yukon",
                                  label: "Yukon",
                                },
                              ]}
                            ></Select>
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Postal Code</b>
                            </h4>
                            name="postalCode"
                            rules={[
                              {
                                required: true,
                                message: "Please input postal code!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Phone Number</b>
                            </h4>
                            name="phoneNumber"
                            rules={[
                              {
                                required: true,
                                message: "Please input phone number!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Date of Birth</b>
                            </h4>
                            width="100%"
                            name="dateofBirth"
                            rules={[
                              {
                                required: true,
                                message: "Please input date of birth!",
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              size="large"
                              onChange={onDatePickerChange}
                            />
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Enter Email</b>
                            </h4>
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Confirm Email</b>
                            </h4>
                            name="confirmEmail"
                            rules={[
                              {
                                required: true,
                                message: "Please input your lastname!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Occupation - Job Title</b>
                            </h4>
                            name="jobTitle"
                          >
                            <Input size="large" />
                          </Form.Item>

                          <Form.Item
                            label=<h4>
                              <b>Occupation - Employer Name</b>
                            </h4>
                            name="employerName"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>SIN</b>
                            </h4>
                            name="sin"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Floor Plan 1</b>
                            </h4>
                            name="fp11"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Floor Plan 1</b>
                            </h4>
                            name="fp12"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Floor Plan 2</b>
                            </h4>
                            name="fp21"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Floor Plan 2</b>
                            </h4>
                            name="fp22"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Floor Plan 3</b>
                            </h4>
                            name="fp31"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b>Floor Plan 3</b>
                            </h4>
                            name="fp32"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Is there a Second Purchaser?</b>
                            </h4>
                            name="secpurchaser"
                            style={{ textAlign: "start" }}
                          >
                            <Radio.Group width="100%">
                              <Space direction="vertical">
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>
                                Are you an investor or buying for yourself/your
                                family (end user)?
                              </b>
                            </h4>
                            name="invoryour"
                            style={{ textAlign: "start" }}
                          >
                            <Radio.Group width="100%">
                              <Space direction="vertical">
                                <Radio value="investor">Investor</Radio>
                                <Radio value="end">End User</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>
                                Do you want to buy parking (if your suite
                                qualifies for parking)?
                              </b>
                            </h4>
                            name="buyPark"
                            style={{ textAlign: "start" }}
                          >
                            <Radio.Group width="100%">
                              <Space direction="vertical">
                                <Radio value="yes">Yes</Radio>
                                <Radio value="no">No</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Do you want to buy a locker (if available)?</b>
                            </h4>
                            name="buyLock"
                            style={{ textAlign: "start" }}
                          >
                            <Radio.Group width="100%">
                              <Space direction="vertical">
                                <Radio value="yes">Yes</Radio>
                                <Radio value="no">No</Radio>
                              </Space>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Name of Agent who helped you </b>
                            </h4>
                            name="nameOfAgent"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Notes</b>
                            </h4>
                            name="notes"
                            style={{ textAlign: "start" }}
                          >
                            <TextArea rows={6} placeholder="notes"></TextArea>
                          </Form.Item>
                          <h4 style={{ textAlign: "start", color: "black" }}>
                            <b>Upload Your Driver's License/Passport/PR Card</b>
                          </h4>

                          <Space
                            direction="vertical"
                            style={{ textAlign: "start", width: "100%" }}
                          >
                            <h4 style={{ textAlign: "start", color: "black" }}>
                              <b>Person 1:</b>
                            </h4>
                            <div style={{ textAlign: "start" }}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture"
                                //defaultFileList={[...fileList]}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload Front
                                </Button>
                                <b style={{ marginLeft: "10px" }}>
                                  Upload Front of Image of Person 1
                                </b>
                              </Upload>
                            </div>
                            <div>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture"
                                //defaultFileList={[...fileList]}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload Back
                                </Button>
                                <b style={{ marginLeft: "10px" }}>
                                  Upload Back of Image of Person 1
                                </b>
                              </Upload>
                            </div>
                          </Space>
                          <br></br>
                          <br></br>
                          <Space
                            direction="vertical"
                            style={{ textAlign: "start", width: "100%" }}
                          >
                            <h4 style={{ textAlign: "start", color: "black" }}>
                              <b>Person 2:</b>
                            </h4>
                            <div style={{ textAlign: "start" }}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture"
                                //defaultFileList={[...fileList]}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload Front
                                </Button>
                                <b style={{ marginLeft: "10px" }}>
                                  Upload Front of Image of Person 2
                                </b>
                              </Upload>
                            </div>
                            <div>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture"
                                //defaultFileList={[...fileList]}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload Back
                                </Button>
                                <b style={{ marginLeft: "10px" }}>
                                  Upload Back of Image of Person 2
                                </b>
                              </Upload>
                            </div>
                          </Space>
                          <br></br>
                          <br></br>
                          <br></br>
                          <Form.Item>
                            <Button
                              type="primary"
                              size="large"
                              htmlType="submit"
                              block
                              style={{
                                background: "green",
                                color: "white",
                              }}
                            >
                              SUBMIT FOR APPROVAL
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default BookAUnit;
