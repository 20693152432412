import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import { useParams } from "react-router-dom";
import { Image } from "antd";
import { capitalizeFirst } from "../../util/string-util";
import { useEffect } from "react";
import loadjs from "loadjs";

function GraphsForROIFree1() {
  useEffect(() => {
    loadjs("../../js/script.js", () => {});
  }, []);

  const { name } = useParams();
  let capitalName = capitalizeFirst(name);
  const src1 = `https://realinvestings3bucket.s3.amazonaws.com/graphs/${name}_freehold_bar.jpg`;
  const src2 = `https://realinvestings3bucket.s3.amazonaws.com/graphs/${name}_freehold_line.jpg`;
  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "9%" }}
      >
        <h3
          class="blog_sp_title"
          style={{ textAlign: "center", color: "green" }}
        >
          <u> {capitalName} Region Freehold Average Price</u>
        </h3>

        <div class="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <div class="col-lg-6">
            <ul class="fp_meta float-left mb0">
              <li class="list-inline-item">
                <Image src={src1} alt="pposter1.png"></Image>
              </li>
            </ul>
          </div>
          <div class="col-lg-6">
            <ul class="fp_meta float-left mb0">
              <li class="list-inline-item">
                <Image src={src2} alt="pposter1.png"></Image>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default GraphsForROIFree1;
