import { useEffect } from "react";
import { Button, Input, Modal, Row, Form, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
const validateMessages = {
  required: "Username is required!",
  types: {
    email: "Email is not a valid email!",
  },
};
const EditDeveloper = ({
  developer,
  open,
  setOpen,
  onOk,
  confirmLoading,
  fetAgain,
  setfetAgain,
}) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const formData = new FormData();

  useEffect(() => {
    async function fetchData(dataUrl) {
      const response = await axiosPrivate.get(`/developers/${developer.key}`);
      console.log("RESPONSE");
      console.log(response);
    }
    fetchData();
  }, [axiosPrivate, developer.key]);

  const handleCancel = () => {
    setOpen(false);
  };

  const onChangeFIleUpload = ({ file, fileList }) => {
    formData.append("image", file);
  };

  const onFinish = async (values) => {
    console.log(values);
    // console.log(values.developer.image.file);
    // Add Developer first

    axiosPrivate
      .post(`/developers/${developer.key}`, {
        name: values.developer.name,
        websiteURL: values.developer.website,
      })
      .then(async (response) => {
        // formData.append("image", values.developer.image.fileList[0]);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        if (values.developer.image != null) {
          axiosPrivate
            .post(`/files/dev/${response.data.data.id}`, formData, config)
            .then((response) => {
              message.success("File Uploaded");
              setfetAgain(fetAgain + 1);
              navigate("/dev", { replace: true });
            })
            .catch((error) => {
              message.error("Error in File Upload ");
            })
            .finally(() => {
              setOpen(false);
            });
        }
      })
      .catch((error) => {
        message.error("Error in Adding Developers");
      })
      .finally(() => {
        setOpen(false);
        setfetAgain(fetAgain + 1);
      });
  };
  const onFinishFailed = (err) => {
    console.log(err);
    alert(err);
  };
  return (
    <Modal
      maskClosable={false}
      title="Edit Developer"
      open={open}
      onOk={onOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
    >
      <Row>
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["developer", "name"]}
            label="Name"
            value="name"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={developer.name}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={["developer", "website"]}
            rules={[{ type: "url", warningOnly: true }]}
            label="Website"
            initialValue={developer.websiteURL}
          >
            <Input />
          </Form.Item>
          <Form.Item name={["developer", "introduction"]} label="Introduction">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name={["developer", "image"]} label="Image">
            <Upload
              maxCount={1}
              listType="picture-card"
              beforeUpload={() => {
                return false;
              }}
              onChange={(file, fileList) => onChangeFIleUpload(file, fileList)}
              fileList={[{ url: developer.imageURL }]}
            >
              {uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              offset: 8,
            }}
          >
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </Modal>
  );
};
export default EditDeveloper;
