import React, { useEffect } from "react";
import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import "./ten-things.css";
import loadjs from "loadjs";

function Careers() {
  useEffect(() => {
    loadjs("./js/script.js", () => {});
  }, []);

  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "9%" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="main_blog_post_content">
                <div
                  class="mbp_thumb_post"
                  style={{ textAlign: "center", backgroundColor: "#eaf7ec" }}
                >
                  <h3 class="blog_sp_title" style={{ color: "green" }}>
                    <b>COOP (Internship Opportunity)</b>
                  </h3>
                  <div
                    class="details"
                    style={{
                      textAlign: "left",

                      padding: "20px",
                    }}
                  >
                    <p class="mb30">
                      <div className="ts">
                        <h4>
                          <b>Job Title:</b>
                        </h4>
                        <h4>
                          <b>
                            Fullstack developer | 70% Backend & 30% Frontend |
                            Python, NodeJs & ReactJS(COOP)
                          </b>
                        </h4>
                      </div>
                      <br />
                      <div className="ts">
                        <h4>
                          <b>Position Overview:</b>
                        </h4>
                        <h5>
                          <b>
                            We are seeking a motivated and proactive final-year
                            college student specializing in computer science to
                            join our team as a Software Development Intern. This
                            unpaid CO-OP opportunity will provide valuable
                            hands-on experience in a dynamic and collaborative
                            work environment.
                            <br />
                          </b>
                        </h5>
                      </div>
                      <br />
                      <div className="ts">
                        <h4>
                          <b>Responsibilities:</b>
                        </h4>
                        <h5>
                          <b>
                            1. Collaborate with the development team to design,
                            develop, test, and deploy web applications.
                            <br />
                            2. Utilize your expertise in JavaScript, Node.js,
                            and Express.js to contribute to the development and
                            maintenance of backend services.
                            <br />
                            3. Able to develop and design classifier, regressor
                            to predict and analyse real-estate date from Toronto
                            Real Estate board using Python, pytorch
                            <br />
                            4. Create and maintain efficient, reusable, and
                            reliable code following best practices.
                            <br /> 5. Work on the front-end development using
                            HTML, CSS, Bootstrap, and Ant Design to ensure a
                            seamless and visually appealing user experience.
                            <br />
                            6. Familiarize yourself with AWS services, including
                            EC2 and Route 56, and actively contribute to
                            cloud-based projects.
                            <br />
                            7. Demonstrate proficiency in ES6 and stay
                            up-to-date with the latest industry trends and
                            advancements in web development.
                            <br />
                            8. Use your knowledge of basic Linux command line
                            operations to troubleshoot and optimize system
                            performance.
                            <br />
                            9. Analyze and design of AI/ML algorithms for
                            prediction and recommendations of real-estates
                          </b>
                        </h5>
                      </div>
                      <br />
                      <div className="ts">
                        <h4>
                          <b>Qualifications:</b>
                        </h4>
                        <h5>
                          <b>
                            1. Currently enrolled in the final year of a diploma
                            or bachelor's program in computer science.
                            <br /> 2. Minimum 1 year of practical experience in
                            JavaScript, Node.js, and Express.js development.
                            <br />
                            3. Proficient in Python, Django, recommendation and
                            prediction algorithms, and classifiers.
                            <br />
                            4. Comfortable working with Express.js framework to
                            build robust and scalable backend services. <br />
                            5. Proficient in HTML, CSS, Bootstrap, and Ant
                            Design for front-end development. <br />
                            6. Familiarity with AWS services, including EC2 and
                            Route 56. <br />
                            7. Basic understanding of Linux command line
                            operations.
                            <br />
                            8. Strong problem-solving skills and the ability to
                            work both independently and collaboratively in a
                            team environment.
                          </b>
                        </h5>
                      </div>
                      <br />
                      <div className="ts">
                        <h4>
                          <b>Additional Skills (Preferred):</b>
                        </h4>
                        <h5>
                          <b>
                            1. Experience with version control systems (e.g.,
                            Git).
                            <br />
                            2. Knowledge of database systems such as MongoDB,
                            PostgresSQL.
                            <br />
                            3. Familiarity with agile development methodologies.
                            <br />
                            4. Excellent communication and interpersonal skills.
                          </b>
                        </h5>
                      </div>
                      <br />
                      <div className="ts">
                        <h4>
                          <b>Duration</b>
                        </h4>
                        <h5>
                          <b>
                            The internship is for a specified period of six
                            months aligning with the requirements of your
                            academic program with probability of full-time
                            hiring. The work hour is 37.5 hours per week.
                          </b>
                        </h5>
                      </div>
                      <br />
                      <div className="ts">
                        <h4>
                          <b>Note</b>
                        </h4>
                        <h5>
                          <b>
                            This is an unpaid CO-OP opportunity designed to
                            provide valuable real-world experience and enhance
                            your skills for future career growth. The knowledge
                            and skills gained during this internship will be a
                            valuable asset as you transition into the
                            professional workforce.
                          </b>
                        </h5>
                      </div>
                      <div className="ts">
                        <h4>
                          <b>How to Apply:</b>
                        </h4>
                        <h5>
                          <b>
                            If you are a passionate and qualified candidate
                            looking to embark on this exciting internship
                            opportunity, please forward your up-to-date resume
                            along with a cover letter highlighting your relevant
                            experience to hr@realinvesting.ca. Please ensure
                            that the subject line of your email includes
                            "Software Development Intern Application - [Your
                            Full Name]."
                            <br />
                            We appreciate all applications, but only shortlisted
                            candidates will be contacted for further
                            consideration.
                          </b>
                        </h5>
                      </div>

                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Careers;
