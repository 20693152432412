import { useState } from "react";
import "./single-property.css";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="readmoretext">
      {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className="readmoreread-or-hide">
        {isReadMore ? (
          <a
            className="text-thm fz14"
            data-toggle="collapse"
            href="homecollapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <br></br>
            Show More <span className="flaticon-download-1 fz12"></span>
          </a>
        ) : (
          <a
            className="text-thm fz14"
            data-toggle="collapse"
            href="homecollapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <br></br>
            Show less <span className="flaticon-upload fz12"></span>
          </a>
        )}
      </span>
    </p>
  );
};

export default ReadMore;
