import React, { useEffect } from "react";
import loadjs from "loadjs";

import team from "../../img/art_team_res.png";
import miketeam from "../../img/mike_team_res.png";
import harryteam1 from "../../img/harry_team_res1.png";

const TeamAbout = () => {
  useEffect(() => {
    loadjs("../js/script.js", () => {});
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="main-title text-center">
              <h2>Meet Our Team</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="team_slider" style={{ textAlign: "center" }}>
              <div class="item">
                <div class="team_member" style={{ minHeight: "600px" }}>
                  <div class="thumb">
                    <center>
                      <div style={{ width: "345px", textAlign: "center" }}>
                        <img
                          //   class="img-fluid"
                          src={team}
                          alt="1.jpg"
                          height="250px"
                        />
                      </div>
                    </center>
                    <div class="overylay"></div>
                  </div>
                  <div class="details" style={{ color: "black" }}>
                    <h4 style={{ color: "black" }}>Arthur Chan</h4>
                    <p style={{ color: "black" }}>
                      <b> Real Estate Broker & Investor</b>
                    </p>
                    <p style={{ color: "black" }}>
                      <b>Founder of Realinvesting.ca</b>
                    </p>
                    <p style={{ color: "black" }}>
                      Licenced Realtor in Ontario & Alberta
                    </p>
                    <p style={{ color: "black" }}>
                      Areas of Expertise - Pre-Construction, Assignments,
                      Resale, & Property Management
                    </p>
                    <p style={{ color: "black" }}>
                      Over 100 Million under Management
                    </p>
                    <p style={{ color: "black" }}>
                      Multi-Award Winning Realtor
                    </p>
                    <p style={{ color: "black" }}>5 Star Reviews</p>
                    <p style={{ color: "black" }}>Office: 647.933.6776 ext 1</p>
                    <p style={{ color: "black" }}>Direct: 416.303.SOLD(7653)</p>
                  </div>
                </div>
                <div class="overylay"></div>
              </div>
              <div class="item">
                <div class="team_member" style={{ minHeight: "600px" }}>
                  <div class="thumb">
                    <center>
                      <div style={{ width: "203px", textAlign: "center" }}>
                        <img
                          //   class="img-fluid"
                          src={miketeam}
                          alt="1.jpg"
                          height="250px"
                        />
                      </div>
                    </center>
                    <div class="overylay"></div>
                  </div>
                  <div class="details" style={{ paddingBottom: "73px" }}>
                    <h4 style={{ color: "black" }}>Michael Tzakas</h4>
                    <p style={{ color: "black" }}>
                      <b> Sales Representative & Investor</b>
                    </p>
                    <p style={{ color: "black" }}>
                      Real Estate and Mortgage Agent
                    </p>
                    <p style={{ color: "black" }}>
                      20 Years Experience in Investing, Buying & Selling Real
                      Estate
                    </p>
                    <p style={{ color: "black" }}>
                      Multi-Award Winning Realtor
                    </p>
                    <p style={{ color: "black" }}>Office: 647.933.6776 ext 2</p>
                    <p style={{ color: "black" }}>Direct: 416.561.3911</p>
                  </div>
                </div>
                <div class="overylay"></div>
              </div>
              <div class="item">
                <div class="team_member" style={{ minHeight: "600px" }}>
                  <div class="thumb">
                    <center>
                      <div style={{ width: "234px", textAlign: "center" }}>
                        <img
                          //   class="img-fluid"
                          src={harryteam1}
                          alt="1.jpg"
                          height="250px"
                          width="200px"
                        />
                      </div>
                    </center>
                    <div class="overylay"></div>
                  </div>
                  <div class="details" style={{ paddingBottom: "90px" }}>
                    <h4 style={{ color: "black" }}>Harry Charilaou</h4>
                    <p style={{ color: "black" }}>
                      <b>Sales Representative & Investor</b>
                    </p>
                    <p style={{ color: "black" }}>
                      Real Estate and Mortgage Agent
                    </p>
                    <p style={{ color: "black" }}>
                      Over 200 Million in Real Estate & Mortgage Transactions
                    </p>
                    <p style={{ color: "black" }}>
                      Multi-Award Winning Realtor & Mortgage Agent
                    </p>
                    <p style={{ color: "black" }}>Office: 647.933.6776 ext 3</p>
                    <p style={{ color: "black" }}>Direct: 416.991.8520</p>
                  </div>
                </div>
                <div class="overylay"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TeamAbout;
