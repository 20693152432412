import React, { useState } from "react";
import { Image, Space } from "antd";
import { Slider, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import kisspngmap from "../../img/kisspng-google-maps-computer-icons.png";

const InstantSearch = () => {
  const navigate = useNavigate();
  const [price, setPrice] = useState({
    defaultMin: 200000,
    defaultMax: 1200000,
  });
  const [size, setSize] = useState({
    defaultMin: 500,
    defaultMax: 1500,
  });

  const onChange = (val) => {
    setPrice({ defaultMin: val[0], defaultMax: val[1] });
  };
  const onSizeChange = (val) => {
    setSize({ defaultMin: val[0], defaultMax: val[1] });
  };
  const handlePriceSearch = () => {
    navigate(`/map?lp=${price.defaultMin}&hp=${price.defaultMax}`);
  };
  const handleSizeSearch = () => {
    navigate(`/map?ls=${size.defaultMin}&hs=${size.defaultMax}`);
  };

  return (
    <section id="findByPriceAndSize" className="property-city pb30">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="main-title text-center">
              <h2>Instant Search</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-xl-6">
            <div className="row">
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "17px",
                }}
              >
                Search By Value
              </p>{" "}
            </div>
            <div className="properti_city home7">
              <div className="thumb">
                <Image
                  preview={false}
                  className="img-fluid w100"
                  height={200}
                  width="100%"
                  style={{ overflow: "hidden" }}
                  src="https://images.ctfassets.net/ydqsf71pc3hg/4QliR0QPcIjgAoi1CAXc0o/0db5acef93c40fc4798b0b858c07bbd0/PB_20220404_22000_60_Queen_St_01_Aerial_View_V2_Post_OP2_LowRes.jpg?fm=jpg&q=100"
                />

                <div className="overlay"></div>
                <div
                  className="bottom-left"
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    left: "16px",
                    right: "20px",
                    textAlign: "center",
                  }}
                >
                  <div className="row" style={{ color: "white" }}>
                    <div
                      className="col-lg-12 col-xl-12"
                      style={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "1",
                        maxHeight: "50px",
                      }}
                    >
                      <a href="/" onClick={(e) => e.preventDefault()}>
                        <Space>
                          <h3 style={{ color: "white" }}>
                            From{" "}
                            <u>
                              {(+price.defaultMin).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                              })}
                            </u>{" "}
                            -{" "}
                            <u>
                              {(+price.defaultMax).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                              })}
                            </u>
                          </h3>
                        </Space>
                      </a>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <div className="changeValue">
                        <Slider
                          onChange={onChange}
                          range
                          defaultValue={[200000, 1200000]}
                          step={50000}
                          min={150000}
                          max={5000000}
                          railStyle={{
                            backgroundColor: "white",
                          }}
                          trackStyle={{
                            backgroundColor: "green",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <Button
                        type="primary"
                        // size="large"
                        icon={<SearchOutlined style={{ fontSize: "20px" }} />}
                        onClick={() => handlePriceSearch()}
                        style={{ color: "white", backgroundColor: "green" }}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-6">
            <div className="row">
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "17px",
                }}
              >
                Search By Square Footage
              </p>{" "}
            </div>
            <div className="properti_city home7">
              <div className="thumb">
                <Image
                  preview={false}
                  className="img-fluid w100"
                  height={200}
                  width="100%"
                  style={{ overflow: "hidden" }}
                  src="https://blog.remax.ca/wp-content/uploads/sites/8/2021/10/Toronto-real-estate-condo-report.jpeg"
                />

                <div className="overlay"></div>
                <div
                  className="bottom-left"
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    left: "16px",
                    right: "20px",
                    textAlign: "center",
                  }}
                >
                  <div className="row" style={{ color: "white" }}>
                    <div
                      className="col-lg-12 col-xl-12"
                      style={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "1",
                        maxHeight: "50px",
                      }}
                    >
                      <a href="/" onClick={(e) => e.preventDefault()}>
                        <Space>
                          <h3 style={{ color: "white" }}>
                            From <u>{+size.defaultMin + " Sq. Ft."}</u> -{" "}
                            <u>{+size.defaultMax + " Sq. Ft."}</u>
                          </h3>
                        </Space>
                      </a>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <div className="changeValue">
                        <Slider
                          onChange={onSizeChange}
                          range
                          defaultValue={[500, 1500]}
                          step={50}
                          min={200}
                          max={2500}
                          railStyle={{
                            backgroundColor: "white",
                          }}
                          trackStyle={{
                            backgroundColor: "green",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <Button
                        type="primary"
                        icon={<SearchOutlined style={{ fontSize: "20px" }} />}
                        // size="large"
                        onClick={() => handleSizeSearch()}
                        style={{ color: "white", backgroundColor: "green" }}
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="main-title text-center">
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "17px",
                  color: "#484848",
                }}
              >
                Search By Map
              </p>{" "}
              <br></br>
              <a href="/map" target="_blank" rel="noopener noreferrer">
                <Image
                  preview={false}
                  height="100px"
                  width="100px"
                  src={kisspngmap}
                ></Image>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default InstantSearch;
