import { useState, useEffect } from "react";
import { Button, Input, Form, Select, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import loadjs from "loadjs";
import { useParams } from "react-router-dom";

const { Dragger } = Upload;

const EditProject = () => {
  const { id } = useParams();

  const axiosPrivate = useAxiosPrivate();
  const [developers, setDevelopers] = useState([]);
  // const [flags, setFlags] = useState();
  const [fetchedProject, setFetchedProject] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [imagefileList, setImagefileList] = useState([]);
  const [floorPlansfileList, setFloorPlansfileList] = useState([]);
  const [otherPdffileList, setOtherPdffileList] = useState([]);

  const propsForImages = {
    name: "images",
    multiple: true,
    maxCount: 15,
    listType: "picture-card",
    onRemove: (file) => {
      const index = imagefileList.indexOf(file);
      const newFileList = imagefileList.slice();
      newFileList.splice(index, 1);
      setImagefileList(newFileList);
    },
    beforeUpload: (file) => {
      setImagefileList([...imagefileList, file]);
      return false;
    },
    imagefileList,
  };

  const propsForFloorPlans = {
    name: "floorplans",
    multiple: true,
    maxCount: 10,
    listType: "picture-card",
    onRemove: (file) => {
      const index = floorPlansfileList.indexOf(file);
      const newFileList = floorPlansfileList.slice();
      newFileList.splice(index, 1);
      setFloorPlansfileList(newFileList);
    },
    beforeUpload: (file) => {
      setFloorPlansfileList([...floorPlansfileList, file]);
      return false;
    },
    floorPlansfileList,
  };

  const propsForOtherPdfs = {
    name: "otherpdfs",
    multiple: true,
    maxCount: 10,
    listType: "picture-card",
    onRemove: (file) => {
      const index = otherPdffileList.indexOf(file);
      const newFileList = otherPdffileList.slice();
      newFileList.splice(index, 1);
      setOtherPdffileList(newFileList);
    },
    beforeUpload: (file) => {
      setOtherPdffileList([...otherPdffileList, file]);
      return false;
    },
    floorPlansfileList,
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  // const onIsSoldChange = (e) => {
  //   setFlags({ ...flags, isSold: e.target.checked });
  //   console.log(`checked = ${e.target.checked}`);
  // };

  // const onIsFeaturedChange = (e) => {
  //   setFlags({ ...flags, isFeautured: e.target.checked });
  //   console.log(`checked = ${e.target.checked}`);
  // };

  useEffect(() => {
    async function fetchData() {
      const response1 = await axiosPrivate.get(`/projects/${id}`);
      setFetchedProject(response1.data.data);
      setIsLoading(false);
      const response = await axiosPrivate.get("/developers");
      const developersData = response.data.data.map((d) => ({
        ...d,
        key: d.id,
      }));
      setDevelopers(developersData);
    }
    fetchData();
    loadjs("/js/script.js", () => {});
  }, [axiosPrivate, id]);

  const onFinish = async (values) => {
    console.log("Success:", values);
    await updateProjects({ ...values.project });
  };

  const updateProjects = async (project) => {
    axiosPrivate
      .post(`/projects/${id}`, project)
      .then((response) => {
        message.success("Project Edited Successfully");
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formData = new FormData();
        imagefileList.forEach((file) => {
          formData.append("images", file);
        });
        floorPlansfileList.forEach((file) => {
          formData.append("floorPlans", file);
        });
        otherPdffileList.forEach((file) => {
          formData.append("pdfFiles", file);
        });

        axiosPrivate
          .post(`/files/project/${response.data.data.id}`, formData, config)
          .then((response) => {
            console.log("after file upload");
            console.log(response);
            message.success("File Uploaded");
          })
          .catch((error) => {
            message.error("Error in File Upload ");
          });
      })
      .catch((error) => {
        message.error("Error in Adding Project");
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {isLoading ? (
        <p>Loading</p>
      ) : (
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <section className="our-dashbord dashbord bgc-f7 pb50">
            <div className="container-fluid ovh">
              <div className="row">
                <div className="col-lg-3 col-xl-2 dn-992 pl0"></div>
                <div className="col-lg-9 col-xl-10 maxw100flex-992">
                  <div className="row">
                    <div className="col-lg-12 mb10">
                      <div className="breadcrumb_content style2">
                        <h2 className="breadcrumb_title">Edit Project</h2>
                        <p>We are glad to see you again!</p>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="my_dashboard_review">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="mb30">Create Listing</h4>
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="propertyTitle">
                                Property Title
                              </label>
                              {/* <input
                            type="text"
                            className="form-control"
                            id="propertyTitle"
                          ></input> */}
                              <Form.Item
                                name={["project", "name"]}
                                // value={fetchedProject.name}
                                initialValue={fetchedProject.name}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your username!",
                                  },
                                ]}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="my_profile_setting_textarea">
                              <label htmlFor="propertyDescription">
                                Developer
                              </label>
                              <Form.Item name={["project", "developers"]}>
                                <Select
                                  mode="multiple"
                                  size="large"
                                  defaultValue="lucy"
                                  style={{ width: "100%" }}
                                  onChange={handleChange}
                                  options={developers.map((d) => ({
                                    value: d.id,
                                    label: d.name,
                                  }))}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="my_profile_setting_textarea">
                              <label htmlFor="propertyDescription">
                                Description
                              </label>
                              <Form.Item
                                name={["project", "description"]}
                                initialValue={fetchedProject.description}
                              >
                                <TextArea rows={5} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input ui_kit_select_search form-group">
                              <label>BUILDING TYPE</label>
                              <br></br>
                              <div>
                                <Form.Item name={["project", "buildingType"]}>
                                  <Select
                                    size="large"
                                    defaultValue="Condo"
                                    style={{ width: "100%" }}
                                    onChange={handleChange}
                                    options={[
                                      {
                                        value: "Condo",
                                        label: "Condo",
                                      },
                                      {
                                        value: "highRise",
                                        label: "High Rise",
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input ui_kit_select_search form-group">
                              <label>DEVELOPMENT STATUS</label>
                              <br></br>
                              <div>
                                <Form.Item
                                  name={["project", "developmentStatus"]}
                                >
                                  <Select
                                    size="large"
                                    defaultValue="underConstruction"
                                    style={{ width: "100%" }}
                                    onChange={handleChange}
                                    options={[
                                      {
                                        value: "underConstruction",
                                        label: "Under Construction",
                                      },
                                      {
                                        value: "completed",
                                        label: "Completed",
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input ui_kit_select_search form-group">
                              <label>SALES STATUS</label>
                              <br></br>
                              <div>
                                <Form.Item name={["project", "timeStatus"]}>
                                  <Select
                                    size="large"
                                    defaultValue="FEATURED"
                                    style={{ width: "100%" }}
                                    onChange={handleChange}
                                    options={[
                                      {
                                        value: "COMING_SOON",
                                        label: "COMING_SOON",
                                      },
                                      {
                                        value: "HOT",
                                        label: "HOT",
                                      },
                                      {
                                        value: "FEATURED",
                                        label: "FEATURED",
                                      },
                                      {
                                        value: "PREVIOUS",
                                        label: "PREVIOUS",
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="formGroupExamplePrice">
                                ARCHITECT
                              </label>
                              <Form.Item
                                name={["project", "architect"]}
                                initialValue={fetchedProject.architect}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="formGroupExampleArea">
                                STOREY
                              </label>
                              <Form.Item
                                name={["project", "storey"]}
                                initialValue={fetchedProject.storey}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input ui_kit_select_search form-group">
                              <label>UNITS</label>
                              <Form.Item
                                name={["project", "units"]}
                                initialValue={fetchedProject.units}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input ui_kit_select_search form-group">
                              <label>ESTIMATED OCCUPANCY</label>
                              <Form.Item
                                name={["project", "estimatedOccupancy"]}
                                initialValue={fetchedProject.estimatedOccupancy}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my_dashboard_review mt30">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="mb30">Location</h4>
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="propertyAddress">Address</label>
                              <Form.Item
                                name={["project", "address"]}
                                initialValue={fetchedProject.address}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="propertyState">
                                County / State
                              </label>
                              <Form.Item
                                name={["project", "state"]}
                                initialValue={fetchedProject.state}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="propertyCity">City</label>
                              <Form.Item
                                name={["project", "city"]}
                                initialValue={fetchedProject.city}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="neighborHood">Neighborhood</label>
                              <Form.Item name={["project", "neighborhood"]}>
                                <Input
                                  size="large"
                                  initialValue={fetchedProject.neighborhood}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="zipCode">Zip</label>
                              <Form.Item name={["project", "zipCode"]}>
                                <Input
                                  size="large"
                                  initialValue={fetchedProject.zipCode}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-4">
                            <div className="my_profile_setting_input ui_kit_select_search form-group">
                              <label>Country</label>
                              <Form.Item
                                name={["project", "country"]}
                                initialValue={fetchedProject.country}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          {/* <div className="col-lg-12">
                        <div className="my_profile_setting_input form-group">
                          <div className="h400 bdrs8" id="map-canvas"></div>
                        </div>
                      </div> */}
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="googleMapLat">
                                Latitude (htmlFor Google Maps)
                              </label>
                              <Form.Item
                                name={["project", "lattitude"]}
                                initialValue={fetchedProject.lattitude}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="googleMapLong">
                                Longitude (htmlFor Google Maps)
                              </label>
                              <Form.Item
                                name={["project", "longitude"]}
                                initialValue={fetchedProject.longitude}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="googleMapLong">Walk Score</label>
                              <Form.Item
                                name={["project", "walkScore"]}
                                initialValue={fetchedProject.walkScore}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="googleMapLong">
                                Transit Score
                              </label>
                              <Form.Item
                                name={["project", "transitScore"]}
                                initialValue={fetchedProject.transitScore}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my_dashboard_review mt30">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="mb30">Detailed Information</h4>
                          </div>
                          <div className="col-lg-6 col-xl-4">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="propertyASize">
                                PRICE(SQ FT)
                              </label>
                              <Form.Item
                                name={["project", "pricePerSqFt"]}
                                initialValue={fetchedProject.pricePerSqFt}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-4">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="sizePrefix">
                                NEIGHBOUR PRICE(SQ FT)
                              </label>
                              <Form.Item
                                name={["project", "nhpricePerSqFt"]}
                                initialValue={fetchedProject.nhpricePerSqFt}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-4">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="landArea">
                                CITY PRICE(SQ FT)
                              </label>
                              <Form.Item
                                name={["project", "ctpricePerSqFt"]}
                                initialValue={fetchedProject.ctpricePerSqFt}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="LASPostfix">LOWEST PRICE</label>
                              <Form.Item
                                name={["project", "lowPrice"]}
                                initialValue={fetchedProject.lowPrice}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="bedRooms">HIGHEST PRICE</label>
                              <Form.Item
                                name={["project", "highPrice"]}
                                initialValue={fetchedProject.highPrice}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="bathRooms">LOWEST SIZE</label>
                              <Form.Item
                                name={["project", "lowSize"]}
                                initialValue={fetchedProject.lowSize}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="garages">HIGHEST SIZE</label>
                              <Form.Item
                                name={["project", "highSize"]}
                                initialValue={fetchedProject.highSize}
                              >
                                <Input size="large" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my_dashboard_review mt30">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="mb30">Detailed Information</h4>
                          </div>
                          <div className="col-lg-12 col-xl-12">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="propertyId">Incentives</label>
                              <Form.Item
                                name={["project", "incentives"]}
                                initialValue={fetchedProject.incentives}
                              >
                                <TextArea rows={5} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="propertyId">
                                Capped Development Charges
                              </label>
                              <Form.Item
                                name={["project", "cappedDevelopmentCharges"]}
                                initialValue={
                                  fetchedProject.cappedDevelopmentCharges
                                }
                              >
                                <TextArea rows={5} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="propertyId">
                                Deposit Structure
                              </label>
                              <Form.Item
                                name={["project", "depositStructure"]}
                                initialValue={fetchedProject.depositStructure}
                              >
                                <TextArea rows={5} />
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12">
                            <div className="my_profile_setting_input form-group">
                              <label htmlFor="propertyId">
                                Reason To Invest
                              </label>
                              <Form.Item
                                name={["project", "reasonToInvest"]}
                                initialValue={fetchedProject.reasonToInvest}
                              >
                                <TextArea rows={5} />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="my_dashboard_review mt30">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="mb30">Status</h4>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <Form.Item name={["project", "isSold"]}>
                                <Checkbox onChange={onIsSoldChange}>
                                  Is Sold
                                </Checkbox>
                              </Form.Item>
                            </div>
                          </div>
                          <div className="col-lg-6 col-xl-6">
                            <div className="my_profile_setting_input form-group">
                              <Form.Item name={["project", "isFeatured"]}>
                                <Checkbox onChange={onIsFeaturedChange}>
                                  Is Featured
                                </Checkbox>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="my_dashboard_review mt30">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="mb30">Gallery</h4>
                          </div>
                          <div className="col-lg-12">
                            <div className="dragger">
                              <Dragger {...propsForImages}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                  Click or drag file to this area to upload
                                </p>
                                <p className="ant-upload-hint">
                                  Support for a single or bulk upload. Strictly
                                  prohibit from uploading company data or other
                                  band files
                                </p>
                              </Dragger>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my_dashboard_review mt30">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="mb30">Floor Plans</h4>
                          </div>
                          <div className="col-lg-12">
                            <div className="dragger">
                              <Dragger {...propsForOtherPdfs}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                  Click or drag file to this area to upload
                                </p>
                                <p className="ant-upload-hint">
                                  Support for a single or bulk upload. Strictly
                                  prohibit from uploading company data or other
                                  band files
                                </p>
                              </Dragger>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my_dashboard_review mt30">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="mb30">Pdf Files</h4>
                          </div>
                          <div className="col-lg-12">
                            <div className="dragger">
                              <Dragger {...propsForFloorPlans}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                  Click or drag file to this area to upload
                                </p>
                                <p className="ant-upload-hint">
                                  Support for a single or bulk upload. Strictly
                                  prohibit from uploading company data or other
                                  band files
                                </p>
                              </Dragger>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my_dashboard_review mt30">
                        <div className="row">
                          <div className="col-lg-12">
                            <Form.Item>
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt50">
                    <div className="col-lg-12">
                      <div className="copyright-widget text-center">
                        <p>© 2023 Real Investing. Made with love.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Form>
      )}
    </>
  );
};
export default EditProject;
