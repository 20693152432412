import arth from "../../img/art-res-1.png";
function BottomMessage() {
  return (
    <>
      <section id="message-property" className="feature-property bgc-f7 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className=" style2">
                <div className="item">
                  <div className="testimonial_grid style2">
                    <div className="thumb">
                      <div className="saying">
                        <b style={{ color: "black" }}>
                          <span
                            className="fa fa-quote-left"
                            style={{ color: "#FD4C62" }}
                          ></span>
                          {"   "}
                          Take control of your financial future by investing in
                          real estate today to build a legacy of wealth for
                          generations to come. Let our experience guide and
                          support you through this exciting journey
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-3">
              <div className=" style2">
                <div className="item">
                  <div className="testimonial_grid style2">
                    <div className="thumb">
                      <img src={arth} alt="1.jpg"></img>
                      <div className="tg_quote">
                        <span className="fa fa-quote-right"></span>
                      </div>
                    </div>
                    <div className="details">
                      <h4>
                        <b>Arthur Chan</b>
                      </h4>
                      <p>
                        <h5>
                          Real Estate Broker, Founder of{" "}
                          <a href="/home">
                            <h5>RealInvesting.ca</h5>
                          </a>
                        </h5>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default BottomMessage;
