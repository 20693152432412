import React, { useEffect } from "react";
import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import "./ten-things.css";
import loadjs from "loadjs";

function FirstTimeToBuy() {
  useEffect(() => {
    loadjs("./js/script.js", () => {});
  }, []);

  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "9%" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="main_blog_post_content">
                <div
                  class="mbp_thumb_post"
                  style={{ backgroundColor: "#eaf7ec" }}
                >
                  <h3
                    class="blog_sp_title"
                    style={{ color: "green", marginBottom: "0px" }}
                  >
                    <b>
                      Investing in Pre Construction projects is the best option
                      for first time buyers and investors
                    </b>
                  </h3>
                  <div
                    class="details"
                    style={{
                      textAlign: "left",
                      paddingTop: "10px",
                    }}
                  >
                    <p
                      class="mb30"
                      style={{
                        fontSize: "18px",
                        color: "black",
                        marginTop: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      <b>Pre-Construction Condo</b> - Entry level in home
                      ownership. <br></br>
                      <b>20% Down All-At-Once</b> - Not required. <br></br>
                      <b>Deposit Structure</b> - Allows you to budget, save and
                      prepare for payments. <br></br>
                      <b>Appreciation</b> - Earn equity. <br></br>
                      <b>Assignment Clause</b> - Option to sell your rights
                      prior to closing date. Mortgage is not required at
                      closing. <br></br>
                      <b>Customization</b> - Decorate by choosing your own
                      colors and upgrades.
                      <br></br>
                    </p>
                  </div>
                  <h4
                    class="blog_sp_title"
                    style={{
                      color: "green",
                      textAlign: "left",
                      marginBottom: "0px",
                    }}
                  >
                    <b>Other Factors to Consider</b>
                  </h4>
                  <div
                    class="details"
                    style={{
                      textAlign: "left",
                      paddingTop: "10px",
                    }}
                  >
                    <p
                      class="mb30"
                      style={{
                        fontSize: "18px",
                        color: "black",
                        marginTop: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      <b>Prices</b> - Based on forecasted future prices.{" "}
                      <br></br>
                      <b>Completion Timelines</b> - 5-6 years for highrise
                      condos and 2-3 years for freehold. <br></br>
                      <b>Rental Rates</b> - Only increases throughout time.{" "}
                      <br></br>
                      <b>Group Buy </b> - Make the purchase with multiple
                      investors. <br></br>
                      <b>Canadian Markets</b> - Vancouver market is valued more
                      than Toronto - It's forecasted that Toronto will soon
                      surpass Vancouver values. <br></br>
                      <b>Mortgage Rates</b> - 2% Variable is Bank of Canada's
                      ideal rate.
                      <br></br>
                      <b>Increased Immigration </b> - Fuels demand for housing.
                      <br></br>
                    </p>
                  </div>
                  <div>
                    <b
                      class="mb30"
                      style={{
                        fontSize: "18px",
                        color: "black",
                        marginTop: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      Numerous projects are available to choose from, and we
                      take pleasure in assisting you in selecting the ones that
                      are most suitable for your requirements as not all
                      projects will have the same upside potential. Follow the
                      path of successful investors by allowing us to work for
                      you.
                    </b>
                  </div>
                  <br></br>
                  <div>
                    <p
                      class="mb30"
                      style={{
                        fontSize: "18px",
                        color: "black",
                        marginTop: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      Click here to view our{" "}
                      <a href="/charts/con/toronto">
                        <u>Equity Charts</u>
                      </a>
                    </p>
                  </div>
                  <div>
                    <p
                      class="mb30"
                      style={{
                        fontSize: "18px",
                        color: "black",
                        marginTop: "0px",
                        marginBottom: "10px",
                      }}
                    >
                      Have questions? Email us:{" "}
                      <a
                        href={`mailto:query@realinvesting.ca?subject= I like to know more about Real Investing &body=Hello! \n I like to know more`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>investorcare@realinvesting.ca</u>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default FirstTimeToBuy;
