import React, { useEffect, useState } from "react";
import { Button, Image, message, Form, Radio, Modal, Input } from "antd";
import "./single-property.css";
import ReadMore from "./readmore";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "./PlainHeader";
import loadjs from "loadjs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import CollabsibleForDetail from "../../ components/collapsible copy";
import SingleGoogleMap from "./SingleGoogleMap";
import LoginRegister from "../../common/login-register";
import Footer from "../../common/footer";

function SingleProperty() {
  const { id } = useParams();

  const [project, setProject] = useState({
    imageUrls: [],
    developers: [],
    lattitude: 43.766682,
    longitude: -79.215736,
  });

  const navigate = useNavigate();

  const handleSimilarProperty = (fd) => {
    navigate(`/pro/${fd.nameSlug}`);
  };

  const [similarProperties, setSimilarProperties] = useState([]);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  // const showModalForProject = () => {
  //   showModal();
  // };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const onFinish = (values) => {
    setOpen(false);
    message.success("Thank you for Reserving!");
    message.info("You will soon hear from us!");

    // axios
    axios.post(`/projects/reserve/${project.id}`, {
      buyerInfo: {
        fullName: values.fullName,
        email: values.Email,
        isRealtor: values.isRealtor,
        phoneNumber: values.phoneNumber,
        typeOfPurchase: values.typeOfPurchase,
      },
    });

    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    console.log("INside single Property");
    console.log("The property Id is ");
    console.log(id);
    async function fetchData() {
      const response = await axios.get(`/projects/name/${id}`);
      setProject(response.data.data);
      console.log(response.data.data);
      const similarPropertiesresponse = await axios.get("/projects");
      setSimilarProperties(similarPropertiesresponse.data.data);
      setIsLoadingComplete(true);
    }
    fetchData();
    loadjs("/js/script.js", () => {});
  }, [id]);

  return (
    <div className="wrapper">
      <div className="preloader"></div>
      <Modal
        footer={null}
        title={
          <h2 style={{ color: "green" }}>
            Register for Floor Plans of {project.name}
          </h2>
        }
        maskClosable={false}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          name="basic"
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[
              {
                required: true,
                message: "Please input your full name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="Email"
            rules={[
              {
                type: "email",
              },
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="isRealtor" label="Are you a Realtor?">
            <Radio.Group>
              <Radio value="yes"> Yes </Radio>
              <Radio value="no"> No </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="typeOfPurchase" label="Type of Purchase">
            <Radio.Group>
              <Radio value="investment"> Investment </Radio>
              <Radio value="toliveIn"> To Live In </Radio>
              <Radio value="undecided">Yet to Decide </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              danger
              htmlType="submit"
              style={{ color: "white", backgroundColor: "green" }}
            >
              Register Now
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      <div className="single_page_listing_style" style={{ paddingTop: "9%" }}>
        <div className="container-fluid p0">
          {/* <div className="row">
            <Image.PreviewGroup>
              <Carousel>
                <div className="spls_style_one pr1 1px">
                  <Image
                    className="img-fluid w100"
                    src="images/property/ls1.jpg"
                    alt="ls1.jpg"
                  />
                </div>
                <div className="spls_style_one">
                  <Image
                    className="img-fluid w100"
                    src="images/property/ls3.jpg"
                    alt="ls3.jpg"
                  />
                </div>
              </Carousel>
            </Image.PreviewGroup>
          </div> */}
          <Image.PreviewGroup>
            <div className="row" style={{ "--bs-gutter-x": "0rem" }}>
              <div className="col-sm-6 col-lg-6 p0">
                <div className="row m0" style={{ "--bs-gutter-x": "0rem" }}>
                  <div className="col-lg-12 p0">
                    <div className="spls_style_one pr1 1px">
                      <Image
                        className="img-fluid w100"
                        // src="images/property/ls1.jpg"
                        height={600}
                        width={"100%"}
                        src={
                          project.imageUrls[0]
                            ? project.imageUrls[0]
                            : "https://realinvestings3bucket.s3.amazonaws.com/others/fallback_image.png"
                        }
                        alt="ls1.jpg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-6 p0">
                <div className="row m0" style={{ "--bs-gutter-x": "0rem" }}>
                  <div className="col-sm-6 col-lg-6 p0">
                    <div className="spls_style_one">
                      <Image
                        className="img-fluid w100"
                        // src="images/property/ls2.jpg"
                        height={300}
                        width={"100%"}
                        src={
                          project.imageUrls[1]
                            ? project.imageUrls[1]
                            : "https://realinvestings3bucket.s3.amazonaws.com/others/fallback_image.png"
                        }
                        alt="ls2.jpg"
                        // fallback="https://realinvestings3bucket.s3.amazonaws.com/others/fallback_image.png"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6 p0">
                    <div className="spls_style_one">
                      <Image
                        className="img-fluid w100"
                        height={300}
                        width={"100%"}
                        src={
                          project.imageUrls[2]
                            ? project.imageUrls[2]
                            : "https://realinvestings3bucket.s3.amazonaws.com/others/fallback_image.png"
                        }
                        alt="ls2.jpg"
                        // fallback="https://realinvestings3bucket.s3.amazonaws.com/others/fallback_image.png"
                        // src="images/property/ls3.jpg"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6 p0">
                    <div className="spls_style_one">
                      <Image
                        className="img-fluid w100"
                        height={300}
                        width={"100%"}
                        src={
                          project.imageUrls[3]
                            ? project.imageUrls[3]
                            : "https://realinvestings3bucket.s3.amazonaws.com/others/fallback_image.png"
                        }
                        alt="ls2.jpg"
                        // fallback="https://realinvestings3bucket.s3.amazonaws.com/others/fallback_image.png"
                        // src="images/property/ls4.jpg"
                        // alt="ls4.jpg"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6 p0">
                    <div className="spls_style_one">
                      <Image
                        className="img-fluid w100"
                        height={300}
                        width={"100%"}
                        src={
                          project.imageUrls[4]
                            ? project.imageUrls[4]
                            : "https://realinvestings3bucket.s3.amazonaws.com/others/fallback_image.png"
                        }
                        alt="ls2.jpg"
                        // fallback="https://realinvestings3bucket.s3.amazonaws.com/others/fallback_image.png"
                        // src="images/property/ls5.jpg"
                        // alt="ls5.jpg"
                      />
                    </div>
                  </div>
                  {project.imageUrls.slice(5).map((url) => (
                    <div className="col-sm-6 col-lg-6 p0" hidden>
                      <div className="spls_style_one">
                        <Image
                          className="img-fluid w100"
                          height={300}
                          width={"100%"}
                          src={url}
                          alt="ls2.jpg"
                          // src="images/property/ls5.jpg"
                          // alt="ls5.jpg"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Image.PreviewGroup>
        </div>
      </div>
      <section className="p0">
        <div className="container">
          <div className="row listing_single_row">
            <div className="col-sm-6 col-lg-7 col-xl-8">
              {/* <div className="single_property_title">
                <a
                  href="images/property/ls1.jpg"
                  className="upload_btn popup-img"
                >
                  <span className="flaticon-photo-camera"></span> View Photos
                </a>
              </div> */}
            </div>
            <div className="col-sm-6 col-lg-5 col-xl-4">
              <div className="single_property_social_share">
                <div className="spss style2 mt10 text-right tal-400">
                  <ul className="mb0">
                    {/* <li className="list-inline-item">
                      <a href="home">
                        <span className="flaticon-transfer-1"></span>
                      </a>
                    </li> */}
                    <li className="list-inline-item" style={{ width: "50%" }}>
                      {project.imageUrls.length} images
                    </li>
                    {/* <li className="list-inline-item">
                      <a href="home">
                        <span className="flaticon-share"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="home">
                        <span className="flaticon-printer"></span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Agent Single Grid View --> */}
      <section className="our-agent-single bgc-f7 pb30-991">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8 mt50">
              <div className="row">
                <div className="col-lg-12">
                  <div className="listing_single_description2 mt30-767 mb30-767">
                    <div className="single_property_title">
                      <h2>{project.name}</h2>
                      <p>
                        {project.address +
                          ", " +
                          project.city +
                          ", " +
                          project.zipCode}
                      </p>
                    </div>
                    <div className="single_property_social_share style2">
                      <div className="price">
                        <h2>
                          <small>From</small>
                          {(+project.lowPrice).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                          })}
                        </h2>
                      </div>
                      <div
                        className="single_property_title"
                        style={{ paddingTop: "5px" }}
                      >
                        <a
                          href="/bookunit"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            size="large"
                            style={{
                              background: "green",
                              borderColor: "white",
                              color: "white",
                              height: "50px",
                              width: "180px",
                            }}
                          >
                            <b
                              className="title"
                              style={{
                                padding: "20px",
                              }}
                            >
                              Reserve Now
                            </b>
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="listing_single_description style2">
                    <div className="lsd_list">
                      <ul className="mb0">
                        <li className="list-inline-item">Apartment</li>
                        <li className="list-inline-item">
                          Storey: {project.storey}
                        </li>
                        <li className="list-inline-item">
                          Units: {project.units}
                        </li>
                        <li className="list-inline-item">
                          Sq Ft starts from: {project.lowSize}
                        </li>
                        <br></br>
                        <br></br>
                        <li className="list-inline-item">
                          Estimated Occupancy: {project.estimatedOccupancy}
                        </li>
                      </ul>
                    </div>
                    <h4 className="mb30">Description</h4>
                    <div className="container">
                      <h2>
                        {isLoadingComplete && (
                          <ReadMore>{project.description}</ReadMore>
                        )}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="additional_details">
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="mb15">Property Details</h4>
                      </div>
                      <div className="col-md-6 col-lg-6 col-xl-4">
                        <ul className="list-inline-item">
                          <li>
                            <span>LOCATION</span>
                            <h4>{project.address}</h4>
                            <h4>{project.city}</h4>
                          </li>
                          <br></br>
                          <li>
                            <span>SALES STATUS</span>
                            <h4>{project.saleStatus}</h4>
                          </li>
                          <br></br>
                          <li>
                            <span>PRICE RANGE</span>
                            <h4>
                              {(+project.lowPrice).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                              })}
                              -
                              {(+project.highPrice).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                              })}
                            </h4>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-xl-4">
                        <ul className="list-inline-item">
                          <li>
                            <span>DEVELOPER</span>
                            {project.developers.map((d) => (
                              <h4>{d.name}</h4>
                            ))}
                          </li>
                          <br></br>
                          <br></br>
                          <li>
                            <span>DEVELOPMENT STATUS</span>
                            <h4>
                              {project.developmentStatus ||
                                "Pre - Construction"}
                            </h4>
                          </li>
                          <br></br>
                          <li>
                            <span>SUIT SIZES</span>
                            <h4>
                              {project.lowSize} Ft - {project.lowSize} Ft
                            </h4>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-xl-4">
                        <ul className="list-inline-item">
                          <li>
                            <span>COMPLETION</span>
                            <h4>{project.estimatedOccupancy}</h4>
                          </li>
                          <br></br>
                          <br></br>
                          <li>
                            <span>BUILDING TYPE</span>
                            <h4>{project.buildingType}</h4>
                          </li>
                          <br></br>
                          <li>
                            <span>AVG PRICE PER FOOT</span>
                            <h4>
                              {(+project.pricePerSqFt).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                              })}
                            </h4>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="additional_details">
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="mb15">Additional details</h4>
                      </div>
                      <div>
                        <CollabsibleForDetail
                          project={project}
                        ></CollabsibleForDetail>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="additional_details">
                    <div className="row">
                      <div className="col-lg-12">
                        <h4 className="mb15">Additional details</h4>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <ul className="list-inline-item">
                          <li>
                            <p>Deposit :</p>
                          </li>
                          <li>
                            <p>Pool Size :</p>
                          </li>
                          <li>
                            <p>Additional Rooms :</p>
                          </li>
                        </ul>
                        <ul className="list-inline-item">
                          <li>
                            <p>
                              <span>20%</span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>300 Sqft</span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>Guest Bath</span>
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <ul className="list-inline-item">
                          <li>
                            <p>Last remodel year :</p>
                          </li>
                          <li>
                            <p>Amenities :</p>
                          </li>
                          <li>
                            <p>Equipment :</p>
                          </li>
                        </ul>
                        <ul className="list-inline-item">
                          <li>
                            <p>
                              <span>1987</span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>Clubhouse</span>
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>Grill - Gas</span>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="property_attachment_area">
                    <h4 className="mb30">Property Attachments</h4>
                    <div className="iba_container style2">
                      <div
                        className="icon_box_area style2"
                        onClick={() =>
                          showModal("Please register to get the floor plan")
                        }
                      >
                        <div className="score">
                          <span className="flaticon-pdf text-thm fz30"></span>
                        </div>
                        <div className="details">
                          <h5>
                            <span className="flaticon-download text-thm pr10"></span>{" "}
                            Package
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="application_statics mt30">
                    <h4 className="mb30">
                      Location{" "}
                      <small className="float-right">
                        {project.address +
                          ", " +
                          project.city +
                          ", " +
                          project.zipCode}
                      </small>
                    </h4>
                    {isLoadingComplete && (
                      <div>
                        <SingleGoogleMap project={project}></SingleGoogleMap>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="application_statics mt30">
                    <h4 className="mb30">Floor plans</h4>
                    <div className="faq_according style2">
                      <div className="iba_container style2">
                        <div
                          className="icon_box_area style2"
                          onClick={() =>
                            showModal("Please register to get the floor plan")
                          }
                        >
                          <div className="score">
                            <span className="flaticon-pdf text-thm fz30"></span>
                          </div>
                          <div className="details">
                            <h5>
                              <span className="flaticon-download text-thm pr10"></span>{" "}
                              Floor Plans
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="walkscore_area mt30">
                    <h4 className="mb30">
                      Walkscore{" "}
                      <span className="float-right">
                        <img
                          src="/images/resource/wscore.png"
                          alt="wscore.png"
                        ></img>
                      </span>
                    </h4>
                    <div className="iba_container">
                      <div className="icon_box_area">
                        <div className="score">
                          <span>{project.walkScore}</span>
                        </div>
                        <div className="details">
                          <h5>Walk Score</h5>
                          <p> Walkable</p>
                        </div>
                      </div>
                      <div className="icon_box_area">
                        <div className="score">
                          <span>{project.transitScore}</span>
                        </div>
                        <div className="details">
                          <h5>Transit Score</h5>
                          <p>Excellent Transit</p>
                        </div>
                      </div>
                    </div>
                    {/* <a className="more_info" href="home">
                      More Details Here
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-12">
                  <h4 className="mt30 mb30">Similar Properties</h4>
                </div>
                {similarProperties.slice(0, 2).map((sp) => {
                  return (
                    <div
                      className="col-lg-6"
                      onClick={() => {
                        handleSimilarProperty(sp);
                      }}
                    >
                      <div className="feat_property">
                        <div className="thumb">
                          <img
                            className="img-whp"
                            src={sp.imageUrls[0]}
                            height={230}
                            width={260}
                            alt="fp1.jpg"
                          ></img>
                          <div className="thmb_cntnt">
                            <ul className="tag mb0">
                              <li className="list-inline-item">
                                <a href="home">Featured</a>
                              </li>
                            </ul>
                            <ul className="icon mb0">
                              {/* <li className="list-inline-item">
                                <a href="home">
                                  <span className="flaticon-transfer-1"></span>
                                </a>
                              </li> */}
                              <li className="list-inline-item">
                                <a href="/home">
                                  <span className="flaticon-heart"></span>
                                </a>
                              </li>
                            </ul>
                            <a className="fp_price" href="home">
                              <small>From</small>
                              {(+sp.lowPrice).toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                              })}
                            </a>
                          </div>
                        </div>
                        <div className="details">
                          <div className="tc_content">
                            <p className="text-thm">Apartment</p>
                            <h4>{sp.name}</h4>
                            <p>
                              <span className="flaticon-placeholder"></span>{" "}
                              {sp.address + ", " + sp.city + ", " + sp.zipCode}
                            </p>
                            <ul className="prop_details mb0">
                              <li className="list-inline-item">
                                <a href="home">Storey: {sp.storey}</a>
                              </li>
                              <li className="list-inline-item">
                                <a href="home">Units: {sp.units}</a>
                              </li>
                              <li className="list-inline-item">
                                <a href="home">Sq Ft from: {sp.lowSize}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
      <a className="scrollToHome" href="home">
        <i className="flaticon-arrows"></i>
      </a>
    </div>
  );
}

export default SingleProperty;
