import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import PlainHeader from "../single-property/PlainHeader";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Image, Row, Select } from "antd";
import loadjs from "loadjs";
import { useSearchParams } from "react-router-dom";
import { Button, Form, Input, Radio } from "antd";
import TextArea from "antd/es/input/TextArea";

const K_MARGIN_TOP = 30;
const K_MARGIN_RIGHT = 30;
const K_MARGIN_BOTTOM = 30;
const K_MARGIN_LEFT = 30;

function Marker() {
  return (
    <>
      <div className="marker"></div>
    </>
  );
}

function getLableDiv(cat, incentives) {
  return (
    <ul className="tag mb1" style={{ width: "100%" }}>
      <li
        className="list-inline-item"
        style={{
          width: incentives ? "60%" : "100%",
          paddingLeft: "5px",
          paddingRight: "5px",
          background: getColor(cat),
        }}
      >
        <a href="home">{getLabelName(cat)}</a>
      </li>
      {incentives && (
        <li
          className="list-inline-item"
          style={{
            backgroundColor: "green",
            width: incentives ? "75%" : "100%",
            marginTop: "5px",
          }}
        >
          <a href="home"> Real Investing Incentives</a>
        </li>
      )}
    </ul>
  );
}

function getLabelName(cat) {
  if (cat === "COMING_SOON") return "Coming Soon";
  else if (cat === "HOT") return "Hot";
  else if (cat === "FEATURED") return "Featured";
  else if (cat === "PREVIOUS") return "Previous";
}

function getColor(cat) {
  if (cat === "COMING_SOON") return "brown";
  else if (cat === "HOT") return "red";
  else if (cat === "FEATURED") return "#FF5A5F";
  else if (cat === "PREVIOUS") return "#FF5A5F";
}
function Browse() {
  const [queryParameters] = useSearchParams();
  const query = queryParameters.get("query");
  const province = queryParameters.get("province")
    ? queryParameters.get("province")
    : "Ontario";
  const ls = queryParameters.get("ls");
  const hs = queryParameters.get("hs");
  const lp = queryParameters.get("lp");
  const hp = queryParameters.get("hp");
  const cat = queryParameters.get("cat");

  const formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [center, setCenter] = useState({
    lat: 43.75535,
    lng: -79.26475,
  });
  const [zoomlevel] = useState(11);
  // const onBoundChange = (values) => {
  //   console.log("On changes values are as below");
  //   console.log(values);
  //   console.log("Map is changed!");
  // };

  // const onChildClick = () => {
  //   window.location.replace("http://localhost:3000/mapsearch#5");
  // };

  const [featuredProjects, setFeaturedProjects] = useState([]);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    loadjs("./js/script.js", () => {});
    async function fetchData() {
      let response = null;
      if (lp && hp) {
        response = await axiosPrivate.get(`/projects?lp=${lp}&hp=${hp}`);
      } else if (ls && hs) {
        response = await axiosPrivate.get(`/projects?ls=${ls}&hs=${hs}}`);
      } else if (query) {
        response = await axiosPrivate.get(`/projects?query=${query}`);
      } else if (cat) {
        console.log("Cat");
        console.log(cat);
        if (cat === "comingSoon")
          response = await axiosPrivate.get(`/projects/new?comingSoon=true`);
        else if (cat === "hot")
          response = await axiosPrivate.get(`/projects/new?hot=true`);
        else if (cat === "featured")
          response = await axiosPrivate.get(`/projects/new?featured=true`);
        else if (cat === "previous")
          response = await axiosPrivate.get(`/projects/new?previous=true`);
      } else {
        response = await axiosPrivate.get(`/projects/new?province=${province}`);
      }
      console.log(response.data.data);
      setFeaturedProjects(response.data.data);
      setIsLoadingComplete(true);

      if (province === "Alberta") {
        setCenter({
          lat: 51.038719,
          lng: -114.06842,
        });
      } else if (province === "Ontario") {
        setCenter({
          lat: 43.75535,
          lng: -79.26475,
        });
      }
    }
    fetchData();
  }, [axiosPrivate, query, lp, hp, ls, hs, cat, province]);

  const onChildClick = (e) => {
    window.location.replace("https://realinvesting.ca/map#" + e);

    for (let i = 0; i < featuredProjects.length; i++) {
      if (parseInt(e) === parseInt(i)) {
        console.log("I am inside");
        console.log(e);
        console.log(i);
        document.getElementById(e).style.backgroundColor = "#90EE90";
      } else {
        console.log("I am outside");
        console.log(e);
        console.log(i);
        document.getElementById(i).style.backgroundColor = "white";
      }
    }
  };

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const handleStateChange = async (value) => {
    setIsLoadingComplete(true);
    let response = await axiosPrivate.get(`/projects/new?province=${value}`);
    setFeaturedProjects(response.data.data);
    if (value === "Alberta") {
      setCenter({
        lat: 51.038719,
        lng: -114.06842,
      });
    } else {
      setCenter({
        lat: 43.75535,
        lng: -79.26475,
      });
    }
  };

  return (
    <div className="wrapper">
      <div className="preloader"></div>

      {/*<!-- Main Header Nav -->*/}
      <PlainHeader></PlainHeader>
      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      <div className="body_content" style={{ marginTop: "9%" }}>
        {/*<!-- Listing Grid View -->*/}
        <section
          id="feature-property"
          className="our-listing-list bgc-f7 pt0 pb0"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="listing_sidebar dn db-991">
                  <div className="sidebar_content_details style3">
                    {/*<!-- <a href="javascript:void(0)" className="closebtn" onclick="closeNav()">&times;</a> -->*/}
                    <div className="sidebar_listing_list style2 mobile_sytle_sidebar mb0">
                      <div className="sidebar_advanced_search_widget">
                        <h4 className="mb25">
                          Advanced Search
                          <a
                            className="filter_closed_btn float-right"
                            href="home"
                          >
                            <small>Hide Filter</small>
                            <span className="flaticon-close"></span>
                          </a>
                        </h4>
                        <ul className="sasw_list style2 mb0">
                          <li className="search_area">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputName1"
                                placeholder="keyword"
                              ></input>
                              <label htmlFor="exampleInputEmail">
                                <span className="flaticon-magnifying-glass"></span>
                              </label>
                            </div>
                          </li>
                          <li className="search_area">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputEmail"
                                placeholder="Location"
                              ></input>
                              <label htmlFor="exampleInputEmail">
                                <span className="flaticon-maps-and-flags"></span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Status</option>
                                  <option>Apartment</option>
                                  <option>Bungalow</option>
                                  <option>Condo</option>
                                  <option>House</option>
                                  <option>Land</option>
                                  <option>Single Family</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Property Type</option>
                                  <option>Apartment</option>
                                  <option>Bungalow</option>
                                  <option>Condo</option>
                                  <option>House</option>
                                  <option>Land</option>
                                  <option>Single Family</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="small_dropdown2">
                              <div id="prncgs" className="btn dd_btn">
                                <span>Price</span>
                                <label htmlFor="exampleInputEmail2">
                                  <span className="fa fa-angle-down"></span>
                                </label>
                              </div>
                              <div className="dd_content2">
                                <div className="pricing_acontent">
                                  {/*<!-- <input type="text" className="amount" placeholder="$52,239"> 
															<input type="text" className="amount2" placeholder="$985,14">
															<div className="slider-range"></div> -->*/}
                                  <span id="slider-range-value1"></span>
                                  <span
                                    className="mt0"
                                    id="slider-range-value2"
                                  ></span>
                                  <div id="slider"></div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Bathrooms</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Bedrooms</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Garages</option>
                                  <option>Yes</option>
                                  <option>No</option>
                                  <option>Others</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Year built</option>
                                  <option>2013</option>
                                  <option>2014</option>
                                  <option>2015</option>
                                  <option>2016</option>
                                  <option>2017</option>
                                  <option>2018</option>
                                  <option>2019</option>
                                  <option>2020</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li className="min_area style2 list-inline-item">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputName2"
                                placeholder="Min Area"
                              ></input>
                            </div>
                          </li>
                          <li className="max_area list-inline-item">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputName3"
                                placeholder="Max Area"
                              ></input>
                            </div>
                          </li>
                          <li>
                            <div id="accordion" className="panel-group">
                              <div className="panel">
                                <div className="panel-heading">
                                  <h4 className="panel-title">
                                    <a
                                      href="#panelBodyRating"
                                      className="accordion-toggle link"
                                      data-toggle="collapse"
                                      data-parent="#accordion"
                                    >
                                      <i className="flaticon-more"></i> Advanced
                                      features
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id="panelBodyRating"
                                  className="panel-collapse collapse"
                                >
                                  <div className="panel-body row">
                                    <div className="col-lg-12">
                                      <ul className="ui_kit_checkbox selectable-list float-left fn-400">
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck1"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck1"
                                            >
                                              Air Conditioning
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck4"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck4"
                                            >
                                              Barbeque
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck10"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck10"
                                            >
                                              Gym
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck5"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck5"
                                            >
                                              Microwave
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck6"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck6"
                                            >
                                              TV Cable
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck2"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck2"
                                            >
                                              Lawn
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck11"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck11"
                                            >
                                              Refrigerator
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck3"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck3"
                                            >
                                              Swimming Pool
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                      <ul className="ui_kit_checkbox selectable-list float-right fn-400">
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck12"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck12"
                                            >
                                              WiFi
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck14"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck14"
                                            >
                                              Sauna
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck7"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck7"
                                            >
                                              Dryer
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck9"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck9"
                                            >
                                              Washer
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck13"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck13"
                                            >
                                              Laundry
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck8"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck8"
                                            >
                                              Outdoor Shower
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck15"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck15"
                                            >
                                              Window Coverings
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_button">
                              <button
                                type="submit"
                                className="btn btn-block btn-thm"
                              >
                                Search
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="listing_sidebar dn-991">
                  <div className="sidebar_content_details is-full-width style2">
                    {/*<!-- <a href="javascript:void(0)" className="closebtn" onclick="closeNav()">&times;</a> -->*/}
                    <div className="sidebar_listing_list style2 mb0">
                      <div className="sidebar_advanced_search_widget">
                        <h4 className="mb25">Advanced Search</h4>
                        <ul className="sasw_list mb0">
                          <li className="search_area">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputName1"
                                placeholder="keyword"
                              ></input>
                              <label htmlFor="exampleInputEmail">
                                <span className="flaticon-magnifying-glass"></span>
                              </label>
                            </div>
                          </li>
                          <li className="search_area">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputEmail"
                                placeholder="Location"
                              ></input>
                              <label htmlFor="exampleInputEmail">
                                <span className="flaticon-maps-and-flags"></span>
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Status</option>
                                  <option>Apartment</option>
                                  <option>Bungalow</option>
                                  <option>Condo</option>
                                  <option>House</option>
                                  <option>Land</option>
                                  <option>Single Family</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Property Type</option>
                                  <option>Apartment</option>
                                  <option>Bungalow</option>
                                  <option>Condo</option>
                                  <option>House</option>
                                  <option>Land</option>
                                  <option>Single Family</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="small_dropdown2">
                              <div id="prncgs2" className="btn dd_btn">
                                <span>Price</span>
                                <label htmlFor="exampleInputEmail2">
                                  <span className="fa fa-angle-down"></span>
                                </label>
                              </div>
                              <div className="dd_content2">
                                <div className="pricing_acontent">
                                  <input
                                    type="text"
                                    className="amount"
                                    placeholder="$52,239"
                                  ></input>
                                  <input
                                    type="text"
                                    className="amount2"
                                    placeholder="$985,14"
                                  ></input>
                                  <div className="slider-range"></div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Bathrooms</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Bedrooms</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Garages</option>
                                  <option>Yes</option>
                                  <option>No</option>
                                  <option>Others</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_two">
                              <div className="candidate_revew_select">
                                <select className="selectpicker w100 show-tick">
                                  <option>Year built</option>
                                  <option>2013</option>
                                  <option>2014</option>
                                  <option>2015</option>
                                  <option>2016</option>
                                  <option>2017</option>
                                  <option>2018</option>
                                  <option>2019</option>
                                  <option>2020</option>
                                </select>
                              </div>
                            </div>
                          </li>
                          <li className="min_area style2 list-inline-item">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputName2"
                                placeholder="Min Area"
                              ></input>
                            </div>
                          </li>
                          <li className="max_area list-inline-item">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputName3"
                                placeholder="Max Area"
                              ></input>
                            </div>
                          </li>
                          <li>
                            <div id="accordion" className="panel-group">
                              <div className="panel">
                                <div className="panel-heading">
                                  <h4 className="panel-title">
                                    <a
                                      href="#panelBodyRating"
                                      className="accordion-toggle link"
                                      data-toggle="collapse"
                                      data-parent="#accordion"
                                    >
                                      <i className="flaticon-more"></i> Advanced
                                      features
                                    </a>
                                  </h4>
                                </div>
                                <div
                                  id="panelBodyRating"
                                  className="panel-collapse collapse"
                                >
                                  <div className="panel-body row">
                                    <div className="col-lg-12">
                                      <ul className="ui_kit_checkbox selectable-list float-left fn-400">
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck16"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck16"
                                            >
                                              Air Conditioning
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck17"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck17"
                                            >
                                              Barbeque
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck18"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck18"
                                            >
                                              Gym
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck19"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck19"
                                            >
                                              Microwave
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck20"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck20"
                                            >
                                              TV Cable
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck21"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck21"
                                            >
                                              Lawn
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck22"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck22"
                                            >
                                              Refrigerator
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck23"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck23"
                                            >
                                              Swimming Pool
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                      <ul className="ui_kit_checkbox selectable-list float-right fn-400">
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck24"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck24"
                                            >
                                              WiFi
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck25"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck25"
                                            >
                                              Sauna
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck26"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck26"
                                            >
                                              Dryer
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck27"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck27"
                                            >
                                              Washer
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck28"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck28"
                                            >
                                              Laundry
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck29"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck29"
                                            >
                                              Outdoor Shower
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="custom-control custom-checkbox">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id="customCheck30"
                                            ></input>
                                            <label
                                              className="custom-control-label"
                                              htmlFor="customCheck30"
                                            >
                                              Window Coverings
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="search_option_button">
                              <button
                                type="submit"
                                className="btn btn-block btn-thm"
                              >
                                Search
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="sidebar_switch mobile_style dn db-991 mt30 mb0">
                  <div id="main2">
                    <span
                      id="open2"
                      className="flaticon-filter-results-button filter_open_btn"
                      style={{ backgroundColor: "green" }}
                    >
                      <Select
                        onChange={(e) => handleStateChange(e)}
                        defaultValue={province}
                        options={[
                          { value: "Ontario", label: "Ontario" },
                          { value: "Alberta", label: "Alberta" },
                        ]}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="sidebar_switch style text-right dn-991">
                  <div id="main">
                    <span
                      id="open"
                      className="flaticon-filter-results-button showFilter sidebarClosed filteropen"
                      style={{ backgroundColor: "green" }}
                    >
                      <Select
                        onChange={(e) => handleStateChange(e)}
                        defaultValue={province}
                        options={[
                          { value: "Ontario", label: "Ontario" },
                          { value: "Alberta", label: "Alberta" },
                        ]}
                      />
                    </span>
                  </div>
                </div>
                <div className="half_map_area" style={{ height: "100vh" }}>
                  <div
                    className="home_two_map style2"
                    style={{ height: "100vh" }}
                  >
                    {/* <div
                      className="map-canvas skin2 half_style"
                      id="contact-google-map"
                      data-map-lat="51.510280"
                      data-map-lng="-0.084028"
                      data-icon-path="images/logo/1.png"
                      data-map-title="Awesome Place"
                      data-map-zoom="14"
                    ></div> */}
                    {isLoadingComplete && (
                      <GoogleMapReact
                        //   options={{ gestureHandling: "greedy" }}
                        bootstrapURLKeys={{
                          key: "AIzaSyCDlyW_Vv5txBpw_IMtCtLGPNw4BFRTy1U",
                        }}
                        center={center}
                        zoom={zoomlevel}
                        //   onChange={(values) => onBoundChange(values)}
                        //   onChildClick={() => onChildClick()}
                        onChildClick={(ind) => onChildClick(ind)}
                        margin={[
                          K_MARGIN_TOP,
                          K_MARGIN_RIGHT,
                          K_MARGIN_BOTTOM,
                          K_MARGIN_LEFT,
                        ]}
                        yesIWantToUseGoogleMapApiInternals
                      >
                        {featuredProjects
                          .filter(
                            (p) =>
                              p.lattitude !== "undefined" ||
                              p.longitude !== "undefined"
                          )
                          .map((marker, ind) => {
                            return (
                              <Marker
                                key={ind}
                                lat={marker.lattitude}
                                lng={marker.longitude}
                              />
                            );
                          })}
                      </GoogleMapReact>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="half_map_area_content">
                  {featuredProjects.length !== 0 && (
                    <div className="row">
                      <div className=" mt30">
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <div className="left_area tac-xsd">
                            <p>{featuredProjects.length} Search results </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    {isLoadingComplete &&
                      featuredProjects.map((fd, cnt) => (
                        <a href={`/pro/${fd.nameSlug}`}>
                          <div className="col-lg-12">
                            <div className="feat_property list" id={cnt}>
                              <div className="thumb">
                                <Image
                                  src={fd.imageUrls[0]}
                                  height={230}
                                  width={360}
                                  alt="pposter1.png"
                                />
                                <div className="thmb_cntnt">
                                  <ul className="icon mb0">
                                    <li className="list-inline-item">
                                      <a href="home">
                                        <span className="flaticon-transfer-1"></span>
                                      </a>
                                    </li>
                                    <li className="list-inline-item">
                                      <a href="home">
                                        <span className="flaticon-heart"></span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="details">
                                <div className="tc_content">
                                  <div className="dtls_headr">
                                    {/* <ul className="tag mb1">
                                      <li
                                        className="list-inline-item"
                                        style={{ backgroundColor: "red" }}
                                      >
                                        <a href="home">Hot</a>
                                      </li>
                                      <li
                                        className="list-inline-item"
                                        style={{ background: "#FF5A5F" }}
                                      >
                                        <a href="home">Featured</a>
                                      </li>

                                      {fd.incentives && (
                                        <li
                                          className="list-inline-item"
                                          style={{
                                            backgroundColor: "green",
                                            width: "50%",
                                            marginTop: "5px",
                                          }}
                                        >
                                          <a href="home">
                                            {" "}
                                            Real Investing Incentives
                                          </a>
                                        </li>
                                      )}
                                    </ul> */}
                                    {getLableDiv(fd.timeStatus, fd.incentives)}
                                    <a className="fp_price" href="home">
                                      <small>From </small>
                                      {(+fd.lowPrice).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 0,
                                      })}
                                    </a>
                                  </div>
                                  {/* <p className="text-thm">Pre-construction</p> */}
                                  <br></br>
                                  <br></br>
                                  <h4>{fd.name}</h4>
                                  <p>
                                    <span className="flaticon-placeholder"></span>{" "}
                                    {fd.address +
                                      ", " +
                                      fd.city +
                                      ", " +
                                      fd.zipCode}
                                  </p>
                                  <ul className="prop_details mb0">
                                    <li className="list-inline-item">
                                      <a href="home">Story: {fd.storey}</a>
                                    </li>
                                    <li className="list-inline-item">
                                      <a href="home">Units: {fd.units}</a>
                                    </li>
                                    <li className="list-inline-item">
                                      <a href="home">
                                        Sq Ft From: {fd.lowSize} ft
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      ))}
                  </div>
                  {featuredProjects.length === 0 && (
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="feat_property list" id="formNofound">
                          <div className="details" style={{ width: "100%" }}>
                            <div style={{ padding: "20px" }}>
                              <Row justify="center">
                                <br></br>
                                <p style={{ fontSize: "20px" }}>
                                  {" "}
                                  <b>
                                    {" "}
                                    Sorry, we don't have info on that project
                                    yet, however, we'll be glad to help you get
                                    it!
                                  </b>
                                </p>
                                <br></br>
                              </Row>
                              <Row>
                                <Form
                                  {...formLayout}
                                  onFinish={onFinish}
                                  onFinishFailed={onFinishFailed}
                                  autoComplete="on"
                                  style={{
                                    width: "100%",
                                    height: "69vh",
                                    paddingRight: "20px",
                                    paddingLeftL: "10px",
                                  }}
                                >
                                  <Form.Item
                                    label="Regarding: Inquiry About"
                                    name="inquiry"
                                    initialValue={query}
                                  >
                                    <Input />
                                  </Form.Item>
                                  <Form.Item
                                    label="I'm Ready to Invest:"
                                    name="readyTo"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input duration!",
                                      },
                                    ]}
                                  >
                                    <Radio.Group
                                      onChange={onChange}
                                      value={value}
                                    >
                                      <Radio value="asap">ASAP</Radio>
                                      <Radio value="one"> 1-2 Months</Radio>
                                      <Radio value="two"> 2-3 Months</Radio>
                                      <Radio value="three">3-6Months</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                  <Form.Item
                                    label="Full Name"
                                    name="full name"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input your username!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Form.Item
                                    label="Contact Number"
                                    name="contact"
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Form.Item
                                    label="Email Address"
                                    name="emailAddress"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input your email!",
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Form.Item label="Notes" name="notes">
                                    <TextArea rows={4} />
                                  </Form.Item>

                                  <Form.Item
                                    style={{
                                      textAlign: "center",
                                    }}
                                    wrapperCol={{
                                      offset: 8,
                                      span: 16,
                                    }}
                                  >
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      size="large"
                                      style={{
                                        width: "100%",
                                        color: "white",
                                        backgroundColor: "green",
                                      }}
                                    >
                                      Send Request
                                    </Button>
                                  </Form.Item>
                                  <Form.Item
                                    wrapperCol={{
                                      offset: 8,
                                      span: 16,
                                    }}
                                  >
                                    <h5>
                                      Expect a reply within 12-24 hours of
                                      clicking "Send Request" - our service is
                                      known for promptness!
                                    </h5>
                                  </Form.Item>
                                </Form>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <a className="scrollToHome" href="home">
        <i className="flaticon-arrows"></i>
      </a>
    </div>
  );
}

export default Browse;
