import { Button, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

const OtherCondos = ({ featuredProjects }) => {
  const navigate = useNavigate();

  const handleOtherCondos = (fd) => {
    navigate(`/pro/${fd.nameSlug}`);
  };
  const handleViewMore = () => {
    navigate(`/map?cat=previous#0`);
  };
  return (
    <section className="our-testimonials" id="previousProject">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="main-title text-center mb20">
              <h2>Previous Projects</h2>
              <br></br>
              {/* <p>Other Condos</p> */}
            </div>
          </div>
        </div>
        <div className="row">
          {featuredProjects.map((fd) => (
            <div
              className="col-md-6 col-lg-4"
              onClick={() => {
                handleOtherCondos(fd);
              }}
            >
              <div className="feat_property home7">
                <div className="thumb">
                  <img
                    className="img-whp"
                    src={fd.imageUrls[0]}
                    alt="fp1.jpg"
                    height={230}
                    width={260}
                  />
                  <div className="thmb_cntnt">
                    {/* <ul className="tag mb0">
                      <li className="list-inline-item">
                        <a href="home">Featured</a>
                      </li>
                    </ul> */}
                    <ul className="icon mb0">
                      <li className="list-inline-item">
                        <a href="home">
                          <span className="flaticon-transfer-1"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="home">
                          <span className="flaticon-heart"></span>
                        </a>
                      </li>
                    </ul>
                    <a className="fp_price" href="home">
                      <small>From</small>
                      {(+fd.lowPrice).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                      })}
                    </a>
                  </div>
                </div>
                <div className="details">
                  <div className="tc_content">
                    <p className="text-thm">Pre-Construction</p>
                    <h4>{fd.name}</h4>
                    <p>
                      <span className="flaticon-placeholder"></span>{" "}
                      {fd.address + ", " + fd.city + ", " + fd.zipCode}
                    </p>
                    <ul className="prop_details mb0">
                      <li className="list-inline-item">
                        <a href="home">Storey: {fd.storey}</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="home">Units: {fd.units}</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="home">Sq Ft from: {fd.lowSize}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {featuredProjects.length === 6 && (
          <Row justify="end">
            <Button
              type="primary"
              onClick={() => handleViewMore()}
              style={{ color: "white", backgroundColor: "green" }}
            >
              {`View more`} <ArrowRightOutlined />
            </Button>
          </Row>
        )}
      </div>
    </section>
  );
};

export default OtherCondos;
