// import Footer from "../../common/footer";
// import Header from "../../common/header";
// import SidebarMobile from "../../common/sidebar-mobile";
import Subscribe from "../../common/subscribe";

// import LoginRegister from "../../common/login-register";
import Featured from "./featured";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import InstantSearch from "./instantsearch";
import HomeSearch from "./search";
import HotThisWeek from "./hotthisweek";
import OtherCondos from "./othercondos";
import loadjs from "loadjs";
import BottomMessage from "./bottom-message";
import { ReactComponent as Cup } from "../../svgimages/cup.svg";
import { ReactComponent as Flat } from "../../svgimages/flat.svg";
import { ReactComponent as Market } from "../../svgimages/market.svg";
import GraphSection from "../ten-things/graph-section";
import ComingSoon from "./comingsoon";
import Team from "./team";

function HomePage() {
  const [comingSoonProjects, setComingSoonProjects] = useState([]);
  const [hotProjects, setHotProjects] = useState([]);
  const [featuredProjects, setFeaturedProjects] = useState([]);
  const [previousProjects, setPreviousProjects] = useState([]);

  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    loadjs("./js/script.js", () => {});
    async function fetchData() {
      const comingSoonProjectsResponse = await axiosPrivate.get(
        "/projects/new?comingSoon=true&count=6"
      );
      setComingSoonProjects(comingSoonProjectsResponse.data.data);

      const hotProjectsResponse = await axiosPrivate.get(
        "/projects/new?hot=true&count=2"
      );
      setHotProjects(hotProjectsResponse.data.data);

      const featuredProjectsResponse = await axiosPrivate.get(
        "/projects/new?featured=true&count=4"
      );
      setFeaturedProjects(featuredProjectsResponse.data.data);

      const previousProjectsResponse = await axiosPrivate.get(
        "/projects/new?previous=true&count=6"
      );
      setPreviousProjects(previousProjectsResponse.data.data);
    }
    fetchData();
  }, [axiosPrivate]);
  return (
    <>
      {/*<!-- Home Design -->*/}
      <HomeSearch></HomeSearch>

      {/*<!-- Why Chose Us -->*/}

      <section
        id="why-chose"
        className="whychose_us pb30"
        style={{ backgroundColor: "#F7F7F7" }}
      >
        <div className="container">
          <div class="row mt50">
            <div class="col-lg-6 offset-lg-3">
              <div class="main-title text-center">
                <h2 style={{ color: "black" }}>
                  <b>WHY DO INVESTORS LOVE US?</b>
                </h2>
                <p style={{ color: "black" }}>
                  <b style={{ fontSize: "20px" }}>
                    V.I.P Service I Exclusive First Access I Expertise
                  </b>
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-lg-4 col-xl-4">
              <div
                class="why_chose_us style2 home7  "
                style={{
                  paddingTop: "px",
                  paddingBottom: "5px",
                  minHeight: "470px",
                }}
              >
                <div class="icon">
                  <Flat
                    fill="red"
                    style={{
                      height: "70px",
                      verticalAlign: "top",
                      marginTop: "20px",
                      marginLeft: "10px",
                    }}
                  ></Flat>
                </div>
                <div class="details">
                  <h4>First Access & Special Incentives </h4>
                  <p>
                    Realinvesting.ca has exclusive first access to exciting
                    projects from reputable developers before the general
                    public. This early access is crucial for securing
                    investments at lower prices, with a wide selection of
                    desirable floor plans and special incentives.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-4">
              <div
                class="why_chose_us style2  home7"
                style={{
                  paddingTop: "40px",
                  paddingBottom: "18px",
                  minHeight: "475px",
                }}
              >
                <div class="icon">
                  <Market
                    fill="red"
                    style={{
                      height: "70px",
                      verticalAlign: "top",
                      marginTop: "20px",
                    }}
                  ></Market>
                </div>
                <div class="details">
                  <h4>Experience Matters </h4>
                  <p>
                    For many years, our pre-construction team has been
                    recognized for their exceptional work, assisting numerous
                    investors in achieving profitable returns on their
                    investments. We remain dedicated to offering our extensive
                    knowledge and expertise to both our current and future
                    clients.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-4">
              <div
                class="why_chose_us style2 home7"
                style={{
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  minHeight: "470px",
                }}
              >
                <div class="icon">
                  <Cup
                    fill="red"
                    style={{
                      height: "70px",
                      verticalAlign: "top",
                      marginTop: "20px",
                    }}
                  ></Cup>
                </div>
                <div class="details">
                  <h4>Reputable Developers</h4>
                  <p>
                    We recognize the importance of choosing the right investment
                    partners, and we take pride in our impeccable track record
                    of zero project cancellations. We collaborate with highly
                    reputable, experienced developers who share our commitment
                    towards excellence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {hotProjects.length > 0 && (
        <HotThisWeek featuredProjects={featuredProjects} />
      )}
      {/*<!-- Feature Properties -->*/}
      {featuredProjects.length > 0 && (
        <Featured featuredProjects={featuredProjects}></Featured>
      )}

      {/*<!-- Coming Soon -->*/}
      {comingSoonProjects.length > 0 && (
        <ComingSoon featuredProjects={comingSoonProjects}></ComingSoon>
      )}

      {/*<!-- Instant Searcg -->*/}
      <InstantSearch></InstantSearch>

      <GraphSection></GraphSection>

      {/*<!-- other condos -->*/}
      {previousProjects.length > 0 && (
        <OtherCondos featuredProjects={previousProjects}></OtherCondos>
      )}
      {/* Bottom message */}
      <Team></Team>

      <BottomMessage></BottomMessage>

      {/* <!-- Our Partners --> */}

      <Subscribe></Subscribe>
    </>
  );
}

export default HomePage;
