import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const HotThisWeek = ({ featuredProjects }) => {
  const navigate = useNavigate();
  const hanldleViewMoreHot = () => {
    navigate(`/map?cat=hot#0`);
  };

  const handleFeaturedProject = (fd) => {
    navigate(`/pro/${fd.nameSlug}`);
  };
  return (
    <section
      className="our-hot-offer parallax"
      id="hot"
      data-stellar-background-ratio="0.3"
      style={{
        backgroundColor: "white",
      }}
    >
      <div className="container ">
        <div className="row mt40">
          <div className="col-md-6 col-lg-4">
            <div className="our_hotoffer">
              <h2 style={{ color: "red" }}> 🔥 Hot Projects This Week</h2>
              <p style={{ color: "red" }}> Projects trending this week</p>
              <Button
                className="btn "
                onClick={hanldleViewMoreHot}
                style={{
                  color: "white",
                  backgroundColor: "red",
                  height: "50px",
                  width: "90%",
                }}
              >
                <b style={{ padding: "20px" }}>View All</b>
              </Button>
            </div>
          </div>
          {featuredProjects.slice(0, 2).map((fd) => (
            <div
              className="col-md-6 col-lg-4"
              onClick={() => handleFeaturedProject(fd)}
            >
              <div
                className="feat_property home7 style2"
                style={{
                  border: "red solid 5px",
                  minHeight: "445px",
                }}
              >
                <div className="thumb" style={{ borderRadius: "0px" }}>
                  <img
                    className="img-whp"
                    height={270}
                    width={360}
                    src={fd.imageUrls[0]}
                    alt="fp2.jpg"
                  ></img>
                  <div className="thmb_cntnt">
                    <ul className="tag mb1">
                      <li
                        className="list-inline-item"
                        style={{
                          width: "120px",
                          backgroundColor: "red",
                        }}
                      >
                        <a href="home">SELLING FAST</a>
                      </li>
                    </ul>
                    <ul className="icon mb0">
                      <li className="list-inline-item">
                        <a href="home">
                          <span className="flaticon-transfer-1"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="home">
                          <span className="flaticon-heart"></span>
                        </a>
                      </li>
                    </ul>
                    <a className="fp_price" href="home">
                      <small>From</small>
                      {(+fd.lowPrice).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                      })}
                    </a>
                  </div>
                </div>

                <div className="details">
                  <div className="tc_content">
                    <p className="text-thm">Pre-Construction</p>
                    <h4>{fd.name}</h4>
                    <p>
                      <span className="flaticon-placeholder"></span>{" "}
                      {fd.address + ", " + fd.city + ", " + fd.zipCode}
                    </p>
                    <ul className="prop_details mb0">
                      <li className="list-inline-item">
                        <a href="home">Storey: {fd.storey}</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="home">Units: {fd.units}</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="home">Sq Ft from: {fd.lowSize}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default HotThisWeek;
