import React, { useEffect } from "react";
import "./footer.css";
import "./header.css";
import loadjs from "loadjs";
import { BackTop, Row } from "antd";

function Footer() {
  useEffect(() => {
    loadjs("./footerjs.js", () => {});
    loadjs("./js/script.js", () => {});
  }, []);
  return (
    <>
      {" "}
      <section className="footer_one">
        <div className="container">
          <div
            className="row"
            style={{ display: "felx", justifyContent: "flex-end" }}
          >
            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 pr0 pl0">
              <div className="footer_about_widget">
                <div className=" d-flex ">
                  <img
                    className="logo2 img-fluid"
                    src="/images/logo/onlyIcon.png"
                    alt="onlyIcon.png"
                    height={101}
                    width={100}
                  ></img>
                  <span
                    style={{
                      lineHeight: "17px",
                      paddingTop: "30px",
                      marginLeft: "10px",
                    }}
                    className=""
                  >
                    <b style={{ fontSize: "30px", color: "white" }}>
                      {"      "}
                      Real Investing
                    </b>

                    <div style={{ marginTop: "0px", paddingTop: "10px" }}>
                      <b style={{ fontSize: "17px", color: "white" }}>
                        {"      "}
                        Investors Choice For Pre-Construction
                      </b>
                    </div>
                  </span>
                </div>
                <br></br>
                <br></br>
                <p style={{ color: "white", fontSize: "17px" }}>
                  Preconstruction I Resale I Condo I Freehold
                </p>
                <p style={{ color: "white", fontSize: "13px" }}>
                  Exclusive, First Access to Investments Opportunities
                </p>
                <div
                  className="socialnetwork"
                  style={{ paddingTop: "15px", paddingRight: "15px" }}
                >
                  <Row justify="start">
                    <ul class="social-network social-circle">
                      <li>
                        <a
                          href={`https://chat.whatsapp.com/LckFNHgIydwGScCTNjAcX0`}
                          target="_blank"
                          rel="noopener noreferrer"
                          class="icoGoogle"
                          title="Google +"
                          style={{ backgroundColor: "#48c756" }}
                        >
                          <i class="fa fa-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.facebook.com/realinvesting/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          class="icoFacebook"
                          title="Facebook"
                          style={{ backgroundColor: "#3b5998" }}
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.instagram.com/realinvesting.ca/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          class="icoRss"
                          title="Rss"
                          style={{ backgroundColor: "#EA4656" }}
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>{" "}
                    </ul>
                  </Row>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
              <div className="footer_qlink_widget">
                <h4>Quick Links</h4>
                <ul className="list-unstyled">
                  <li>
                    <h5>
                      <a
                        href="/mortgage-calc/ON"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "white", lineHeight: "0" }}
                      >
                        Payment calculator
                      </a>
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="row">
                <Row justify="center">
                  <div className="footer_contact_widget">
                    <h4>Contact Us</h4>
                  </div>
                </Row>
                <Row justify="center">
                  <div className="footer_contact_widget">
                    <h5 style={{ color: "white" }}>REALINVESTING.CA OFFICES</h5>
                  </div>
                </Row>
                <Row justify="space-between">
                  <div className="col-lg-6">
                    <Row justify="end">
                      <div className="footer_contact_widget">
                        <ul className="list-unstyled">
                          <li></li>
                          <h5 style={{ color: "white" }}>TORONTO</h5>
                          <li>
                            <h5 style={{ color: "white" }}>
                              RE/MAX HALLMARK REALTY INC.
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              685 Sheppard Ave East unit 401
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              North York, ON, M2K 1B6
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              Office/Brokerage No: (416) 494-7653
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              Direct No: (647) 933-6776
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              Cell Phone: (416) 303-7653
                            </h5>
                          </li>
                          <li>
                            <a
                              href="mailto: investercare@realinvesting.ca"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <h5 style={{ color: "white" }}>
                                investercare@realinvesting.ca
                              </h5>
                            </a>

                            <a
                              href="/realtor-partner"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h5
                                style={{ color: "white", paddingTop: "10px" }}
                              >
                                Realtor Partner
                              </h5>
                            </a>

                            <a
                              href="/referral-program"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h5
                                style={{ color: "white", paddingTop: "10px" }}
                              >
                                Referral Program Non-Realtors
                              </h5>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Row>
                  </div>
                  <div className="col-lg-6">
                    <Row justify="end">
                      <div className="footer_contact_widget">
                        <ul className="list-unstyled">
                          <li></li>
                          <h5 style={{ color: "white" }}>CALGARY</h5>
                          <li>
                            <h5 style={{ color: "white" }}>
                              RE/MAX REAL ESTATE CENTRAL
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              5211 4th Street NE
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              Calgary, AB, T2K 6J5
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              Office/Brokerage No: (403) 216-1600
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              Direct No: (647) 933-6776
                            </h5>
                          </li>
                          <li>
                            <h5 style={{ color: "white" }}>
                              Cell Phone: (416) 303-7653
                            </h5>
                          </li>
                          <li>
                            <a
                              href="mailto: investercare@realinvesting.ca"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <h5 style={{ color: "white" }}>
                                investercare@realinvesting.ca
                              </h5>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Row>
                  </div>
                </Row>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <p style={{ color: "white", fontSize: "12px" }}>
            E.&O.E. Terms and conditions apply - The information presented here
            was collected from professionals and sources that were considered
            reliable. However, REALINVESTING.CA & RE/MAX Hallmark Realty Ltd,
            along with its representatives, do not guarantee its accuracy and
            will not be liable for any errors or omissions. It is recommended
            that potential buyers verify all information provided. Any
            renderings, pricing, incentives, or other details are subject to
            change without notice. We do not work for any of the developers
            featured on this website. This is not an attempt to solicit buyers
            already under contract.
          </p>
          <p style={{ color: "white", fontSize: "12px" }}>
            *By submitting your email address, you agree to receive useful
            promotional & marketing materials, VIP Exclusive first access for
            pre-construction projects via email from Real Investing.ca. We will
            not share your information with any third party and you can always
            unsubscribe any time : )
          </p>
          <p style={{ color: "white", fontSize: "12px" }}>
            **Inventory is based on a first come, first serve basis. Don't Wait
            - Reserve Now
          </p>
        </div>
      </section>
      {/* <!-- Our Footer Bottom Area --> */}
      <section className="footer_middle_area pt30 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6">
              <div className="footer_menu_widget">
                <ul>
                  <li className="list-inline-item">
                    <a href="/home">Home</a>
                  </li>
                  <li className="list-inline-item">
                    <a href="/about-us">About Us</a>
                  </li>
                  <li className="list-inline-item">
                    <a href="/term-of-service">Terms & Conditions</a>
                  </li>
                  <li className="list-inline-item">
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li className="list-inline-item">
                    <a href="/careers">Careers</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="copyright-widget text-right">
                <p>© 2023 Real Investing. Made with love.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a className="scrollToHome" href="home">
        <i className="flaticon-arrows"></i>
      </a>
      <div>
        <BackTop />
      </div>
    </>
  );
}
export default Footer;
