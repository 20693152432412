import { Row } from "antd";

import arthur from "../../img/art-res-1.png";

function MessageFromArthur() {
  return (
    <>
      <section
        id="message-property"
        className="feature-property bgc-f7 pb30"
        style={{ padding: "0px", backgroundColor: "#eaf7ec" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className=" style2">
                <div className="item">
                  <div className="testimonial_grid style2">
                    <div className="thumb">
                      <div className="saying">
                        <b style={{ color: "black" }}>
                          <span
                            className="fa fa-quote-left"
                            style={{ color: "#FD4C62" }}
                          ></span>{" "}
                          Establishing a legacy through today's real estate
                          investments is the key to achieving future
                          generational wealth.{" "}
                        </b>
                        <br></br>
                        <br></br>
                        <b style={{ color: "black" }}>
                          Whether you are a new or seasoned investor, we invite
                          you to experience the exceptional level of service we
                          offer.{" "}
                        </b>
                        <br></br>
                        <br></br>
                        <b style={{ color: "black" }}>
                          Our team is committed to prioritizing our investors'
                          best interests and providing them with the highest
                          level of care and dedication.{" "}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-3">
              <div className=" style2">
                <div className="item">
                  <div className="testimonial_grid style2">
                    <div className="thumb">
                      <img src={arthur} alt="1.jpg"></img>
                      <div className="tg_quote">
                        <span className="fa fa-quote-right"></span>
                      </div>
                    </div>
                    <div className="details">
                      <h4>
                        <b>Arthur Chan</b>
                      </h4>
                      <p>
                        <h5>
                          Real Estate Broker, Founder of{" "}
                          <a href="/home">
                            <h5>RealInvesting.ca</h5>
                          </a>
                          <h5>Office No: (416) 494-7653</h5>
                          <h5>Direct No: (647) 933-6776</h5>
                          <h5>Cell Phone: (416) 303-7653</h5>
                        </h5>
                        <Row justify="center">
                          <h6 style={{ color: "black" }}>
                            Arthur Chan holds dual real estate licenses in
                            Toronto & Calgary
                          </h6>
                        </Row>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default MessageFromArthur;
