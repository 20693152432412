import React, { useEffect, useState } from "react";

import loadjs from "loadjs";
import "./header.css";
import { Button, Popover, Row } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";

import {
  content,
  equityChartContent,
  marketsContent,
  contacContent,
  FirstTimeBuyeContent,
} from "../common/listmenu/listmenu";
import RegisterModal from "./register-modal";

function Header() {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const contentForWhatsapp = (
    <div>
      <p>
        <b>Keep up to date on our exclusive investment opportunities!</b>
      </p>
      <p>
        <b>
          Unjoin anytime and mute chat notifications. Search, scroll,and browse
          at leisure.
        </b>
      </p>
      <p>
        <b>
          Message us if you see an opportunity that suits you or your{" "}
          <a href="/referral-program" target="_blank" rel="noopener noreferrer">
            <u>referral.</u>
          </a>
        </b>
      </p>
    </div>
  );

  useEffect(() => {
    // loadjs("./footerjs.js", () => {});
    loadjs("./js/script.js", () => {});
    loadjs("./js/ace-responsive-menu.js", () => {});
  }, []);
  return (
    <>
      <header
        className="header-nav menu_style_home_one home7 navbar-scrolltofixed stricky main-menu scroll-to-fixed-fixed slideIn animated"
        style={{
          zIndex: "1000",
          position: "fixed",
          top: "0px",
          marginLeft: "0px",
          width: "100%",
          left: "0px",
        }}
      >
        <div className="container-fluid p0">
          {/* <!-- Ace Responsive Menu --> */}

          <div
            className="socialnetwork"
            style={{ paddingTop: "15px", paddingRight: "15px" }}
          >
            <Row justify="end">
              <ul class="social-network social-circle">
                <Popover content={contentForWhatsapp} placement="bottomLeft">
                  <Button style={{ height: "40px" }}>
                    <a
                      href={`https://chat.whatsapp.com/LckFNHgIydwGScCTNjAcX0`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b
                        style={{
                          fontWeight: "0",
                          fontSize: "14px",
                          color: "green",
                        }}
                      >
                        <u>Join our Whatsapp Group</u>
                        {"   "}
                        <WhatsAppOutlined
                          style={{ fontSize: "30px", color: "#52c41a" }}
                        />
                      </b>
                    </a>
                  </Button>
                  <Popover content={contentForWhatsapp} placement="bottomLeft">
                    {/* <li>
                      <a
                        href="#"
                        class="icoGoogle"
                        title="Google +"
                        style={{ backgroundColor: "#48c756" }}
                      >
                        <i class="fa fa-whatsapp"></i>
                      </a>
                    </li> */}
                  </Popover>
                  {/* </Button> */}
                </Popover>
                {/* |{" "}
                <b style={{ fontWeight: "0", fontSize: "14px" }}>
                  Connect with us{" "}
                </b>
                <li>
                  <a
                    href="#"
                    class="icoFacebook"
                    title="Facebook"
                    style={{ backgroundColor: "#3b5998" }}
                  >
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    class="icoRss"
                    title="Rss"
                    style={{ backgroundColor: "#EA4656" }}
                  >
                    <i class="fa fa-instagram"></i>
                  </a>
                </li> */}
              </ul>
            </Row>
          </div>

          <nav>
            {/* <!-- Menu Toggle btn--> */}
            <div className="menu-toggle">
              <img
                className="nav_logo_img img-fluid"
                // src="images/header-logo.png"
                // alt="header-logo.png"
                src="/images/logo/onlyIcon.png"
                alt="onlyIcon.png"
                height={50}
                width={50}
              ></img>
              <button type="button" id="menu-btn">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
            <div className=" d-flex justify-content-between">
              <div>
                <a
                  href="/"
                  className="navbar_brand float-left dn-smd"
                  style={{ marginTop: "0px" }}
                >
                  <img
                    className="logo1 img-fluid"
                    // src="images/header-logo2.png"
                    // alt="header-logo.png"
                    src="/images/logo/onlyIcon.png"
                    alt="onlyIcon.png"
                    height={50}
                    width={50}
                  ></img>
                  <img
                    className="logo2 img-fluid"
                    // src="images/header-logo2.png"
                    // alt="header-logo2.png"
                    src="/images/logo/onlyIcon.png"
                    alt="onlyIcon.png"
                    height={50}
                    width={50}
                  ></img>
                  <span style={{ lineHeight: "17px" }} className="menu_slogan">
                    <b style={{ fontSize: "23px" }}>Real Investing</b>
                    <div style={{ marginTop: "0px", paddingTop: "0px" }}>
                      <b style={{ fontSize: "12px" }}>
                        Investors Choice For Pre-Construction
                      </b>
                    </div>
                  </span>
                </a>
              </div>
              {/* <!-- Responsive Menu Structure--> */}
              {/* <!--Note: declare the Menu style in the data-menu-style="horizontal" (options: horizontal, vertical, accordion) --> */}
              <div>
                <ul
                  id="respMenu"
                  className="ace-responsive-menu text-right"
                  data-menu-style="horizontal"
                >
                  <li>
                    <a href="/">
                      <b style={{ fontSize: "18px", fontWeight: "900" }}>
                        <span className="title">Home</span>
                      </b>
                    </a>
                  </li>

                  <li>
                    <a href="/">
                      <span className="title">
                        <Popover content={content} placement="bottomLeft">
                          <b style={{ fontSize: "18px", fontWeight: "900" }}>
                            Projects
                          </b>
                        </Popover>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="/">
                      <span className="title">
                        <Popover
                          content={equityChartContent}
                          placement="bottomLeft"
                        >
                          <b style={{ fontSize: "18px", fontWeight: "900" }}>
                            Equity Charts
                          </b>
                        </Popover>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="/">
                      <span className="title">
                        <Popover
                          content={marketsContent}
                          placement="bottomLeft"
                        >
                          <b style={{ fontSize: "18px", fontWeight: "900" }}>
                            Markets
                          </b>
                        </Popover>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="/first-time-buyer">
                      <span className="title">
                        <Popover
                          content={FirstTimeBuyeContent}
                          placement="bottomLeft"
                        >
                          <b style={{ fontSize: "18px", fontWeight: "900" }}>
                            First Time Buyer
                          </b>
                        </Popover>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="/">
                      <span className="title">
                        <Popover content={contacContent} placement="bottomLeft">
                          <b style={{ fontSize: "18px", fontWeight: "900" }}>
                            Contact
                          </b>
                        </Popover>
                      </span>
                    </a>
                  </li>

                  <li>
                    <a
                      href="/bookunit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        size="large"
                        style={{
                          background: "green",
                          borderColor: "white",
                          color: "white",
                          height: "40px",
                          width: "180px",
                        }}
                      >
                        <b
                          className="title"
                          style={{
                            padding: "20px",
                          }}
                        >
                          Reserve A Unit
                        </b>
                      </Button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <RegisterModal
        open={open}
        handleOk={handleOk}
        confirmLoading={confirmLoading}
        handleCancel={handleCancel}
      ></RegisterModal>
    </>
  );
}

export default Header;
