import { useState, useEffect } from "react";
import { Space, Table, Button, Input, Row, Col, Image } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import AddDeveloper from "./add-developer";
import { axiosPrivate } from "../../api/axios";
import DeleteDeveloper from "./delete-developer";
import EditDeveloper from "./edit-developer";

const ListDevelopers = () => {
  const [loadingState, setLoadingState] = useState(true);
  const [developers, setDevelopers] = useState([]);
  const [fetAgain, setfetAgain] = useState(1);

  useEffect(() => {
    async function fetchData(dataUrl) {
      const response = await axiosPrivate.get("/developers");
      const developersData = response.data.data.map((d) => ({
        name: d.name,
        imageURL: d.imageURL,
        key: d.id,
      }));
      setDevelopers(developersData);
      setLoadingState(false);
    }
    fetchData();
  }, [fetAgain]);

  const handleOnChange = (e) => {
    // const searchKey = e.target.value;
    // setLoadingState(true);
    // if (e.target.value == "") {
    //   setDataUrl("");
    // } else {
    //   const url = "/items/search?keyWord=" + searchKey;
    //   setDataUrl(url);
    //   setPagination({});
    // }
  };
  // Modal Changes
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedDeveloper, setSelectedDeveloper] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a href="home">{text}</a>,
    },
    {
      title: "Image",
      dataIndex: "imageURL",
      key: "imageURL",
      render: (_, { imageURL }) => <Image width={50} src={imageURL}></Image>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <ul className="view_edit_delete_list mb0">
            <li
              className="list-inline-item"
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <Button
                type="link"
                primary
                size="large"
                onClick={() => {
                  setSelectedDeveloper(record);
                  setEditModalOpen(true);
                }}
              >
                <span className="flaticon-edit"></span>
              </Button>
            </li>
            <li
              className="list-inline-item"
              data-toggle="tooltip"
              data-placement="top"
              title="Delete"
            >
              <Button
                type="link"
                danger
                size="large"
                onClick={() => {
                  setSelectedId(record.key);
                  setDeleteModalOpen(true);
                }}
              >
                <span className="flaticon-garbage"></span>
              </Button>
            </li>
            <li className="list-inline-item" size="large">
              <Button type="link" style={{ color: "green" }}>
                <span className="flaticon-view"></span>
              </Button>
            </li>
          </ul>
        </Space>
      ),
    },
  ];
  return (
    <section className="our-dashbord dashbord bgc-f7 pb50">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-xl-2 dn-992 pl0"></div>
          <div className="col-lg-9 col-xl-10 maxw100flex-992">
            <div className="row">
              <div className="col-xl-12">
                <div className="application_statics">
                  <div style={{ textAlign: "center" }}>
                    <h4>Developers</h4>
                  </div>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Row justify="space-between">
                      <Col>
                        <div className="add_new_dev">
                          <Button onClick={() => showModal()} type="primary">
                            Add New Developer
                          </Button>
                        </div>
                      </Col>
                      <Col>
                        <div className="search_in_datatable">
                          <Input
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            onChange={(e) => {
                              handleOnChange(e);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Table
                      loading={loadingState}
                      columns={columns}
                      dataSource={developers}
                    />
                  </Space>
                </div>
              </div>
            </div>
            <div className="row mt50">
              <div className="col-lg-6 offset-lg-3">
                <div className="copyright-widget text-center">
                  <p>© 2020 Find House. Made with love.</p>
                </div>
              </div>
            </div>
            {/* <Modal
              title="Title"
              open={open}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <p>{modalText}</p>
            </Modal> */}
            <AddDeveloper
              maskClosable={false}
              title="Title"
              open={open}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
              hideModal={hideModal}
              setfetAgain={setfetAgain}
              fetAgain={fetAgain}
            >
              <p>{modalText}</p>
            </AddDeveloper>
            <DeleteDeveloper
              id={selectedId}
              open={deleteModalOpen}
              setOpen={setDeleteModalOpen}
              setfetAgain={setfetAgain}
              fetAgain={fetAgain}
            ></DeleteDeveloper>
            <EditDeveloper
              developer={selectedDeveloper}
              maskClosable={false}
              title="Edit Developer"
              open={editModalOpen}
              setOpen={setEditModalOpen}
              confirmLoading={confirmLoading}
              footer={null}
              destroyOnClose={true}
              fetAgain={fetAgain}
              setfetAgain={setfetAgain}
            ></EditDeveloper>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ListDevelopers;
