import React, { useState } from "react";
import { Button, Input, Select } from "antd";
import { Search } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const HomeSearch = () => {
  const [query, setQuery] = useState("");
  const [province, setProvince] = useState("Ontario");
  const navigate = useNavigate();
  const handleOnChange = (e) => {
    setQuery(e.target.value);
  };

  const handleProvinceChange = (e) => {
    setProvince(e);
  };

  const handleSearch = () => {
    // window.location.replace(`http://localhost:3000/map#0?query=${query}`);
    navigate(`/map?query=${query}&province=${province}`);
  };
  return (
    <section className="home-seven">
      <div className="container">
        <div className="row posr">
          <div className="col-lg-10 offset-lg-1">
            <div className="home_content home7">
              <div className="home-text text-center">
                <h3 className="fz55">Real Estate Wealth Starts Here</h3>
                <p className="fz18 color-white" style={{ fontSize: "20px" }}>
                  Build your Canadian investment portfolio in a few clicks.
                </p>
              </div>
              <div className="home_adv_srch_opt home7">
                <div className="home1-advnc-search home7">
                  <ul className="h1ads_1st_list mb0 text-center">
                    <li className="list-inline-item">
                      <div className="search_option_two">
                        <div className="candidate_revew_select">
                          <Select
                            defaultValue="Ontario"
                            onChange={(val) => handleProvinceChange(val)}
                            style={{
                              width: 300,
                            }}
                            size="large"
                            // onChange={handleChange}
                            options={[
                              {
                                value: "Alberta",
                                label: "Alberta",
                              },
                              {
                                value: "Ontario",
                                label: "Ontario",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <div className="form-group">
                        <Input
                          size="large"
                          placeholder="Search by Project Name, City Or Developer"
                          onChange={(val) => handleOnChange(val)}
                          onPressEnter={handleSearch}
                        />
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <div className="search_option_button">
                        <Button
                          className="btn btn-thm"
                          onClick={handleSearch}
                          style={{ backgroundColor: "green", height: "40px" }}
                        >
                          <Search size={20}></Search>
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HomeSearch;
