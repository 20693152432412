import HomePage from "./pages/home/home";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import SingleProperty from "./pages/single-property/single-property";
import BrowseMap from "./pages/browse-map/browse-map";
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/login/login";
import SignUp from "./pages/signup/signup";
import Summary from "./pages/dashboard/summary";
import ListDevelopers from "./pages/dashboard/list-developers";
import { AuthProvider } from "./context/AuthProvider";
import PersistLogin from "./auth/persistLogin";
import RequireAuth from "./auth/requireAuth";
import Layout from "./pages/home/layout";
import ListProject from "./pages/dashboard/list-project";
import AddProject from "./pages/dashboard/add-project";
import ScrollToTop from "./ScrollToTop";
import EditProject from "./pages/dashboard/edit-project";
import "./App.css";

import TenThings from "./pages/ten-things/ten-things";
import TermOfService from "./pages/ten-things/term-of-services";
import PrivacyPolicy from "./pages/ten-things/privacy-policy";
import AboutUs from "./pages/ten-things/about-us";
import CalVsToronto from "./pages/ten-things/cal-vs-toronto";
import GraphsForROI1 from "./pages/ten-things/graphs-for-roi1";
import GraphsForROIFree1 from "./pages/ten-things/graphs-for-roi_Free1";
import BookAUnit from "./pages/ten-things/book-a-unit";
import MortgageCalculator from "./pages/ten-things/mortgage-calculators";
import FirstTimeToBuy from "./pages/ten-things/first-time-buyer";
import RealtorPartner from "./pages/ten-things/realtor-partner";
import WhyInvestCal from "./pages/ten-things/why-invest-cal";
import ReferralProgram from "./pages/ten-things/referral-program";
import Careers from "./pages/ten-things/careers";

function App() {
  // return <HomePage></HomePage>;
  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/pro/:id" element={<SingleProperty />}></Route>
            <Route
              path="/charts/fh/:name"
              element={<GraphsForROIFree1 />}
            ></Route>
            <Route path="/charts/con/:name" element={<GraphsForROI1 />}></Route>
            <Route path="/map" element={<BrowseMap />}></Route>
            <Route path="/ten-things" element={<TenThings />}></Route>
            <Route path="/why-cal" element={<WhyInvestCal />}></Route>
            <Route
              path="/first-time-buyer"
              element={<FirstTimeToBuy />}
            ></Route>
            <Route path="/term-of-service" element={<TermOfService />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/cal-vs-ont" element={<CalVsToronto />}></Route>
            <Route path="/bookunit" element={<BookAUnit />}></Route>
            <Route path="/realtor-partner" element={<RealtorPartner />}></Route>
            <Route path="/careers" element={<Careers />}></Route>
            <Route
              path="/referral-program"
              element={<ReferralProgram />}
            ></Route>
            <Route
              path="/mortgage-calc/:province"
              element={<MortgageCalculator />}
            ></Route>
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth />}>
                <Route element={<Dashboard />}>
                  <Route path="/dev" element={<ListDevelopers />}></Route>
                  <Route path="/summary" element={<Summary />}></Route>
                  <Route path="/property" element={<ListProject />}></Route>
                  <Route path="/addproperty" element={<AddProject />}></Route>
                  <Route
                    path="/property/edit/:id"
                    element={<EditProject />}
                  ></Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </ScrollToTop>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
