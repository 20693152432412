import React, { useEffect } from "react";
import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import "./ten-things.css";
import loadjs from "loadjs";

function TenThings() {
  useEffect(() => {
    loadjs("./js/script.js", () => {});
  }, []);

  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "9%" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="main_blog_post_content">
                <div
                  class="mbp_thumb_post"
                  style={{ textAlign: "center", backgroundColor: "#eaf7ec" }}
                >
                  <h3 class="blog_sp_title" style={{ color: "green" }}>
                    <b>
                      10 Things to know when investing in a Pre- Construction
                      Condo in Toronto
                    </b>
                  </h3>
                  <div
                    class="details"
                    style={{
                      textAlign: "left",

                      padding: "20px",
                    }}
                  >
                    <p class="mb30">
                      <h4>
                        <b>
                          Whether you're new to investing or a seasoned pro, our
                          team is here to guide you through 10 crucial topics
                          and help you make informed decisions. Additionally, we
                          offer an outstanding assignment channel and property
                          management division to help you sell your rights prior
                          to occupancy or explore leasing options. Let our
                          experience work for you.
                        </b>
                      </h4>
                    </p>
                    <p>
                      <h4>
                        <b>1. Developer</b>
                        <br />
                        <b>2. Location</b>
                        <br />
                        <b>3. Price</b>
                        <br />
                        <b>4. Deposit Structure & Financing</b>
                        <br />
                        <b>5. Reviewing The Purchase Agreement & Timelines</b>
                        <br />
                        <b>6. Your Rights as a Purchaser</b>
                        <br />
                        <b>7. Unit Floor Plan & Building Layout</b>
                        <br />
                        <b>8. Forecasted View & Direction</b>
                        <br />
                        <b>9. Amenities</b>
                        <br />
                        <b>10. Preparing for Occupancy</b>
                      </h4>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default TenThings;
