import { Image } from "antd";
import { Carousel } from "react-bootstrap";

import toronto_condo_bar from "../../img/toronto_condo_bar.jpg";
import toronto_condo_line from "../../img/toronto_condo_line.jpg";
import toronto_freehold_bar from "../../img/toronto_freehold_bar.jpg";
import toronto_freehold_line from "../../img/toronto_freehold_line.jpg";

import york_condo_bar from "../../img/york_condo_bar.jpg";
import york_condo_line from "../../img/york_condo_line.jpg";
import york_freehold_bar from "../../img/york_freehold_bar.jpg";
import york_freehold_line from "../../img/york_freehold_line.jpg";

import peel_condo_bar from "../../img/peel_condo_bar.jpg";
import peel_condo_line from "../../img/peel_condo_line.jpg";
import peel_freehold_bar from "../../img/peel_freehold_bar.jpg";
import peel_freehold_line from "../../img/peel_freehold_line.jpg";

import durham_condo_bar from "../../img/durham_condo_bar.jpg";
import durham_condo_line from "../../img/durham_condo_line.jpg";
import durham_freehold_bar from "../../img/durham_freehold_bar.jpg";
import durham_freehold_line from "../../img/durham_freehold_line.jpg";

import simcoe_condo_bar from "../../img/simcoe_condo_bar.jpg";
import simcoe_condo_line from "../../img/simcoe_condo_line.jpg";
import simcoe_freehold_bar from "../../img/simcoe_freehold_bar.jpg";
import simcoe_freehold_line from "../../img/simcoe_freehold_line.jpg";

import halton_condo_bar from "../../img/halton_condo_bar.jpg";
import halton_condo_line from "../../img/halton_condo_line.jpg";
import halton_freehold_bar from "../../img/halton_freehold_bar.jpg";
import halton_freehold_line from "../../img/halton_freehold_line.jpg";

function GraphSection() {
  const listOfCity = ["toronto", "york", "peel", "durham", "simcoe", "halton"];

  const listofGraph = [
    toronto_condo_bar,
    toronto_condo_line,
    toronto_freehold_bar,
    toronto_freehold_line,

    york_condo_bar,
    york_condo_line,
    york_freehold_bar,
    york_freehold_line,

    peel_condo_bar,
    peel_condo_line,
    peel_freehold_bar,
    peel_freehold_line,

    durham_condo_bar,
    durham_condo_line,
    durham_freehold_bar,
    durham_freehold_line,

    simcoe_condo_bar,
    simcoe_condo_line,
    simcoe_freehold_bar,
    simcoe_freehold_line,

    halton_condo_bar,
    halton_condo_line,
    halton_freehold_bar,
    halton_freehold_line,
  ];

  const getCityCondoGraph = (city, condoOrFreehold, barOrLine) => {
    console.log(city + "_" + condoOrFreehold + "_" + barOrLine);
    return city + "_" + condoOrFreehold + "_" + barOrLine;
  };

  let i = 0;
  return (
    <section id="graph-property" className="feature-property bgc-f7 pb30">
      <div className="container">
        <div className="row" style={{ textAlign: "center" }}>
          <h2>Equity Charts</h2>
        </div>
        <br></br>
        <div className="row" style={{ textAlign: "center" }}>
          <Carousel
            interval={2000}
            style={{ width: "100%", height: "100%" }}
            dots={false}
          >
            {listOfCity.map((city) => (
              <Carousel.Item key={1}>
                <div className="row">
                  <div className="col-lg-3" style={{ textAlign: "right" }}>
                    <Image
                      style={{ paddingBottom: "10px" }}
                      // src={`https://realinvestings3bucket.s3.amazonaws.com/graphs/${city}_condo_bar.jpg`}
                      src={listofGraph[i++]}
                    />
                  </div>
                  <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <Image
                      style={{ paddingBottom: "10px" }}
                      src={listofGraph[i++]}
                    />
                  </div>
                  <div className="col-lg-3" style={{ textAlign: "right" }}>
                    <Image
                      style={{ paddingBottom: "10px" }}
                      src={listofGraph[i++]}
                    />
                  </div>
                  <div className="col-lg-3" style={{ textAlign: "left" }}>
                    <Image
                      style={{ paddingBottom: "10px" }}
                      src={listofGraph[i++]}
                    />
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}
export default GraphSection;
