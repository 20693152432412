import React, { useEffect } from "react";
import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import "./ten-things.css";
import loadjs from "loadjs";
import MessageFromArthur from "./message-from-arthur";
import { ReactComponent as Flat } from "../../svgimages/flat.svg";
import { ReactComponent as Cup } from "../../svgimages/cup.svg";
import { ReactComponent as Market } from "../../svgimages/market.svg";
import TeamAbout from "../home/teamAbout";

function AboutUs() {
  useEffect(() => {
    loadjs("./js/script.js", () => {});
  }, []);

  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "9%" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="main_blog_post_content">
                <div
                  class="mbp_thumb_post"
                  style={{ textAlign: "center", backgroundColor: "#eaf7ec" }}
                >
                  <h3 class="blog_sp_title" style={{ color: "green" }}>
                    <b style={{ lineHeight: "1.3" }}>About Us</b>
                  </h3>
                  <div
                    class="details"
                    style={{
                      textAlign: "left",
                      padding: "20px",
                    }}
                  >
                    <p class="mb30" style={{ textAlign: "center" }}>
                      <h4>
                        <b style={{ lineHeight: "1.3" }}>
                          Since the 1970s, my family has been actively investing
                          in Toronto Real Estate. From a young age, I witnessed
                          the rapid growth of the city's population and the
                          subsequent surge in real estate demand. Our team has
                          achieved great success, having completed thousands of
                          real estate transactions and guiding both first-time
                          and experienced investors to achieve positive returns
                          on their investments. Our dedication to providing the
                          highest level of service extends to both our existing
                          clients and those seeking our services for the first
                          time. As a result, we have received multiple awards
                          for our accomplishments in the industry.
                        </b>
                      </h4>
                      <br></br>
                      <div className="ts" style={{ textAlign: "center" }}>
                        <div
                          class="why_chose_us  "
                          style={{
                            padding: "15px",
                            backgroundColor: "#eaf7ec",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          <div
                            class="icon"
                            style={{ backgroundColor: "green" }}
                          >
                            <Flat
                              fill="red"
                              style={{
                                height: "70px",
                                verticalAlign: "top",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                            ></Flat>
                            {/* <span
                              class="flaticon-high-five"
                              style={{ color: "white" }}
                            ></span> */}
                          </div>
                          <br></br>
                          <h4 style={{ color: "green" }}>
                            <b>First Access & Special Incentives</b>
                          </h4>
                          <h4>
                            <b>
                              Realinvesting.ca has exclusive first access to
                              exciting projects from reputable developers before
                              the general public. This early access is crucial
                              for securing investments at lower prices, with a
                              wide selection of desirable floor plans and
                              special incentives.
                            </b>
                          </h4>
                        </div>
                      </div>
                      <br></br>
                      <div className="ts" style={{ textAlign: "center" }}>
                        <div
                          class="why_chose_us  "
                          style={{
                            padding: "15px",
                            backgroundColor: "#eaf7ec",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          <div
                            class="icon"
                            style={{ backgroundColor: "green" }}
                          >
                            <Market
                              fill="red"
                              style={{
                                height: "70px",
                                verticalAlign: "top",
                                marginTop: "20px",
                              }}
                            ></Market>
                          </div>
                          <br></br>
                          <h4 style={{ color: "green" }}>
                            <b>Experience Matters</b>
                          </h4>
                          <h4>
                            <b>
                              Our award-winning pre-construction team has
                              completed 1,000 + transactions, helping ,investors
                              achieve positive returns. We are dedicated to
                              providing high-level service to both new and
                              existing clients.
                            </b>
                          </h4>
                        </div>
                      </div>
                      <br></br>
                      <div className="ts" style={{ textAlign: "center" }}>
                        <div
                          class="why_chose_us  "
                          style={{
                            padding: "15px",
                            backgroundColor: "#eaf7ec",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          <div
                            class="icon"
                            style={{ backgroundColor: "green" }}
                          >
                            <Cup
                              fill="red"
                              style={{
                                height: "70px",
                                verticalAlign: "top",
                                marginTop: "20px",
                              }}
                            ></Cup>
                          </div>
                          <br></br>
                          <h4 style={{ color: "green" }}>
                            <b>Reputable Builders</b>
                          </h4>
                          <h4>
                            <b>
                              On a daily basis, we collaborate with channel
                              developer partners and their internal sales teams
                              to provide offerings from highly reputable
                              developers.
                            </b>
                          </h4>
                        </div>
                      </div>
                      <br></br>
                      <div className="ts" style={{ textAlign: "center" }}>
                        <div
                          class="why_chose_us  "
                          style={{
                            padding: "15px",
                            backgroundColor: "#eaf7ec",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          <h4 style={{ color: "green" }}>
                            <b>S-I-M-P-L-E</b>
                          </h4>
                          <h4>
                            <br></br>
                            <b style={{ fontWeight: "900" }}>
                              We strive to make investing with us a
                              straightforward and hassle-free experience. Our
                              comprehensive range of full services includes
                              top-level mortgage advisors, real estate lawyers,
                              and in-house property management.
                            </b>
                          </h4>
                        </div>
                      </div>
                      <section id="absctonid">
                        <TeamAbout></TeamAbout>
                      </section>
                      <div
                        class="why_chose_us  "
                        style={{
                          padding: "15px",
                          backgroundColor: "#eaf7ec",
                          border: "none",
                          textAlign: "center",
                        }}
                      >
                        <div className="ts" style={{ textAlign: "center" }}>
                          <h4 style={{ color: "green" }}>
                            <b style={{ fontWeight: "bolder" }}>
                              Message From Arthur
                            </b>
                            <MessageFromArthur></MessageFromArthur>
                          </h4>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default AboutUs;
