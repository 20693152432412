import React, { useEffect } from "react";
import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import "./ten-things.css";
import loadjs from "loadjs";
import { Button, Form, Input, message } from "antd";

import TextArea from "antd/es/input/TextArea";
import axios from "../../api/axios";

function RealtorPartner() {
  const onFinish = async (values) => {
    console.log(values);
    axios
      .post("/realtor-partner", values)
      .then((response) => {
        message.success(
          "Thank you for filling the form. You will soon hear from us!"
        );
      })
      .catch((error) => {
        message.success(
          "Thank you for filling the form. You will soon hear from us!"
        );
      });
  };
  const onFinishFailed = () => {};

  useEffect(() => {
    loadjs("./js/script.js", () => {});
  }, []);

  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "6%" }}
      >
        <div class="container">
          <div class="row">
            <div class="main_blog_post_content">
              <div class="mbp_thumb_post">
                <div>
                  <p class="mb30">
                    <div className="col-lg-12 ">
                      <div className="testimonial_grid style2">
                        <h2 style={{ color: "green" }}>
                          Realtors
                          <span style={{ fontSize: "25px" }}>&trade;</span> Love
                          to Partner With Us!
                        </h2>
                        <b
                          style={{
                            color: "black",
                            fontSize: "17px",
                            paddingTop: "5px",
                            textAlign: "left",
                          }}
                        >
                          We welcome all Realtors from all brokerages throughout
                          Canada in partnering with us!
                        </b>

                        <p
                          style={{
                            color: "black",
                            fontSize: "17px",
                            paddingTop: "5px",
                          }}
                        >
                          <b>
                            Securing your investor / buyer with exclusive
                            pre-construction access and establishing fast
                            communication channels is what we are known to
                            accomplish. Your client is in good hands while
                            you're compensated and there's no need to switch
                            brokerages - we work alongside you and your
                            investor, ensuring positive collaboration.
                          </b>
                        </p>
                        <p style={{ color: "black", fontSize: "17px" }}>
                          <b>Register for more information</b>
                        </p>
                      </div>
                    </div>
                    <br></br>
                    <div class="row">
                      <div
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Form
                          layout="vertical"
                          name="basic"
                          style={{
                            width: "800px",
                            borderStyle: "solid",
                            borderColor: "green",
                            padding: "20px",
                          }}
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                        >
                          <Form.Item
                            style={{
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b> First Name</b>
                            </h4>
                            name="firtsname"
                            rules={[
                              {
                                required: true,
                                message: "Please input your firstname!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            style={{
                              paddingLeft: "10px",
                              display: "inline-block",
                              width: "50%",
                            }}
                            label=<h4>
                              <b> Last Name</b>
                            </h4>
                            name="lastname"
                            rules={[
                              {
                                required: true,
                                message: "Please input your lastname!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>cellphone</b>
                            </h4>
                            name="cellphone"
                            rules={[
                              {
                                required: true,
                                message: "Please input your cellphone",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>

                          <Form.Item
                            label=<h4>
                              <b> Email</b>
                            </h4>
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                            ]}
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Current Brokerage</b>
                            </h4>
                            name="currentbrokerage"
                          >
                            <Input size="large" />
                          </Form.Item>

                          <Form.Item
                            label=<h4>
                              <b>City</b>
                            </h4>
                            name="city"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>
                                Which Project(s) is your investor/buyer
                                interested in?
                              </b>
                            </h4>
                            name="interestedProject"
                          >
                            <Input size="large" />
                          </Form.Item>
                          <Form.Item
                            label=<h4>
                              <b>Notes</b>
                            </h4>
                            name="notes"
                          >
                            <TextArea size="large" rows={4} />
                          </Form.Item>

                          <Form.Item>
                            <Button
                              type="primary"
                              size="large"
                              htmlType="submit"
                              block
                              style={{
                                background: "green",
                                color: "white",
                              }}
                            >
                              SUBMIT
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default RealtorPartner;
