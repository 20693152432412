import React, { useEffect } from "react";
import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import "./ten-things.css";
import loadjs from "loadjs";

function ReferralProgram() {
  useEffect(() => {
    loadjs("./js/script.js", () => {});
  }, []);

  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "6%" }}
      >
        <div class="container">
          <div class="row">
            <div class="main_blog_post_content">
              <div class="mbp_thumb_post">
                <div>
                  <p class="mb30">
                    <div className="col-lg-12 ">
                      <div className="testimonial_grid style2">
                        <h2 style={{ color: "green" }}>
                          Earn Cash! Become a Real Investing Ambassador!
                        </h2>
                        <br></br>
                        <b
                          style={{
                            color: "black",
                            fontSize: "17px",
                            paddingTop: "5px",
                            textAlign: "left",
                          }}
                        >
                          As an ambassador, Realinvesting.ca will pay you
                          anywhere between $600 to $1,500 per transaction based
                          on purchase price.
                        </b>
                        <br></br>
                        <br></br>
                        <p
                          style={{
                            color: "black",
                            fontSize: "17px",
                            paddingTop: "5px",
                          }}
                        >
                          <b>
                            If someone you know, a friend, family member, or
                            colleague, is interested in investing in a
                            pre-construction project or buying/selling with us
                            but has not signed an exclusive employment contract
                            with an agent (Buyer Representation Agreement), you
                            can earn a referral fee by telling them about our
                            services.
                          </b>
                        </p>
                        <br></br>
                        <p style={{ color: "black", fontSize: "17px" }}></p>
                      </div>
                    </div>
                    <br></br>
                    <div class="row">
                      <div
                        style={{
                          textAlign: "left",
                          justifyContent: "left",
                          color: "black",
                        }}
                      >
                        <h4 style={{ color: "black" }}>
                          <b>
                            1. Send your referral the link below:{" "}
                            <a
                              href="https://realinvesting.ca/bookunit"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <b style={{ color: "black" }}>
                                <u>https://realinvesting.ca/bookunit</u>
                              </b>
                            </a>
                          </b>
                        </h4>

                        <h4 style={{ color: "black" }}>
                          <b>
                            2. Send us an email to :{" "}
                            <a
                              href="mailto: investercare@realinvesting.ca"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <b style={{ color: "black" }}>
                                <u>investercare@realinvesting.ca</u>
                              </b>
                            </a>
                          </b>
                        </h4>
                        <h4 style={{ color: "black" }}>
                          <b>
                            &nbsp;&nbsp;&nbsp; Subject: Ambassador Program (In
                            the email body, include the contact information of
                            your referral)
                          </b>
                        </h4>
                        <h4 style={{ color: "black" }}>
                          <b>
                            3. Feel free to share any resource material from our
                            website with your referral.
                          </b>
                        </h4>
                        <h4 style={{ color: "black" }}>
                          <b>
                            4. At this point, we will handle the rest. You can
                            be confident that your referral is in excellent
                            care.
                          </b>
                        </h4>
                        <h4 style={{ color: "black" }}>
                          <b>
                            5. You will receive a referral form to sign, and
                            your payment will be disbursed.
                          </b>
                        </h4>
                        <br></br>
                        <br></br>
                        <h4 style={{ color: "black" }}>
                          <b>
                            Yes, it's that simple! Contact us for more details.
                          </b>
                        </h4>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default ReferralProgram;
