import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import Collabsible from "../../ components/collapsible";
import { Telephone, Envelope } from "react-bootstrap-icons";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Image,
  Modal,
  Form,
  Radio,
  Button,
  Input,
  message,
  Row,
  Col,
  Collapse,
} from "antd";
import "./featured.css";

const { Panel } = Collapse;

function Featured({ featuredProjects }) {
  const navigate = useNavigate();
  const [reserveProject, setReserveProject] = useState({});

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModalForProject = (fd) => {
    setReserveProject(fd);
    showModal();
  };

  const showModal = () => {
    setOpen(true);
    showModalForProject();
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const axiosPrivate = useAxiosPrivate();
  const onFinish = (values) => {
    setOpen(false);
    message.success("Thank you for Reserving!");
    message.info("You will soon hear from us!");

    // axios
    axiosPrivate.post(`/projects/reserve/${reserveProject.id}`, {
      buyerInfo: {
        fullName: values.fullName,
        email: values.Email,
        isRealtor: values.isRealtor,
        phoneNumber: values.phoneNumber,
        typeOfPurchase: values.typeOfPurchase,
      },
    });

    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleViewMore = () => {
    navigate(`/map?cat=featured#0`);
  };

  return (
    <>
      <Modal
        footer={null}
        title={
          <h2 style={{ color: "#ff5a5f" }}>Reserve {reserveProject.name}</h2>
        }
        maskClosable={false}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          name="basic"
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[
              {
                required: true,
                message: "Please input your full name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="Email"
            rules={[
              {
                type: "email",
              },
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your phone number",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="isRealtor" label="Are you a Realtor?">
            <Radio.Group>
              <Radio value="yes"> Yes </Radio>
              <Radio value="no"> No </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="typeOfPurchase" label="Type of Purchase">
            <Radio.Group>
              <Radio value="investment"> Investment </Radio>
              <Radio value="toliveIn"> To Live In </Radio>
              <Radio value="undecided">Yet to Decide </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button type="primary" danger htmlType="submit">
              Reserve Now
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <section id="feature-property" className="feature-property bgc-f7 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="main-title text-center">
                <h2>Featured Projects</h2>
                <p>
                  Exclusive Access to Exciting Projects in Toronto & Calgary
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {featuredProjects.map((fd) => (
              <div className="col-md-6 col-lg-6" key={fd.id}>
                <div className="feat_property home7">
                  <div className="thumb">
                    <Carousel
                      interval={null}
                      style={{ width: "100%", height: "100%" }}
                      dots={false}
                    >
                      {fd.imageUrls.map((imgUrl) => (
                        <Carousel.Item key={imgUrl}>
                          <Image height={410} src={imgUrl} />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <div className="thmb_cntnt">
                      <ul className="tag mb1">
                        {/* <li
                          className="list-inline-item"
                          style={{ backgroundColor: "red" }}
                        >
                          <a href="home">Hot</a>
                        </li> */}
                        <li
                          className="list-inline-item"
                          style={{ background: "#FF5A5F" }}
                        >
                          <a href="home">Featured</a>
                        </li>

                        {fd.incentives && (
                          <li
                            className="list-inline-item"
                            style={{
                              backgroundColor: "green",
                              width: "100%",
                              marginTop: "5px",
                            }}
                          >
                            <a href="home"> Real Investing Incentives</a>
                          </li>
                        )}
                      </ul>
                      <ul className="icon mb0">
                        <li className="list-inline-item">
                          <a href="home">
                            <span className="flaticon-transfer-1"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="home">
                            <span className="flaticon-heart"></span>
                          </a>
                        </li>
                      </ul>
                      <a
                        className="fp_price"
                        href="home"
                        style={{ bottom: "30px" }}
                      >
                        <small>From </small>
                        {(+fd.lowPrice).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                        })}
                      </a>
                    </div>
                  </div>
                  <div className="details">
                    <div className="tc_content">
                      <p className="text-thm">Pre-Construction</p>
                      {/* <Link to={`/pro/${fd.id}`}> */}
                      <a href={`/pro/${fd.nameSlug}`}>
                        <h4>{fd.name}</h4>
                      </a>
                      {/* </Link> */}
                      <p>
                        <span className="flaticon-placeholder"></span>
                        {fd.address + ", " + fd.city + ", " + fd.zipCode}
                      </p>
                      <ul className="prop_details mb0">
                        <li className="list-inline-item">
                          Storey: {fd.storey}
                        </li>
                        <li className="list-inline-item">Units:{fd.units}</li>
                        <li className="list-inline-item">
                          Size starts from(sq ft): {fd.lowSize} ft
                        </li>
                        <li className="list-inline-item">
                          Estimated Occupancy: {fd.estimatedOccupancy}
                        </li>
                      </ul>
                      <Collapse defaultActiveKey={["1"]} ghost>
                        <Panel
                          showArrow={false}
                          header=<Button
                            size="large"
                            style={{
                              width: "100%",
                              color: "white",
                              backgroundColor: "green",
                            }}
                          >
                            View Details
                          </Button>
                          key="2"
                          style={{ paddingLeft: "0px", paddingTop: "10px" }}
                          className="custom"
                        >
                          <br></br>
                          <Collabsible project={fd}></Collabsible>
                        </Panel>
                      </Collapse>
                    </div>
                    <div
                      className="fp_footer"
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Row justify="space-between" gutter={[16, 24]}>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <a
                            href="/bookunit"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button
                              type="button"
                              className="btn primary btn-outline-danger"
                              style={{ width: "100%", height: "40px" }}
                            >
                              Reserve
                            </button>
                          </a>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            style={{ width: "100%", height: "40px" }}
                          >
                            <a
                              href={`mailto:query@realinvesting.ca?subject= Reserve ${fd.name}&body=Hello! \n I like to reserve ${fd.name}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Envelope></Envelope> Mail
                            </a>
                          </button>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                          <button
                            type="button"
                            className="btn  btn-outline-success"
                            style={{ width: "100%", height: "40px" }}
                          >
                            <a
                              href={`https://wa.me/+14163037653?text=Hello! \n I like to reserve ${fd.name}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Telephone></Telephone> Whatsapp
                            </a>
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {featuredProjects.length === 4 && (
            <Row justify="end">
              <Button
                type="primary"
                onClick={() => handleViewMore()}
                style={{ color: "white", backgroundColor: "green" }}
              >
                {`View more`} <ArrowRightOutlined />
              </Button>
            </Row>
          )}
        </div>
      </section>
    </>
  );
}
export default Featured;
