import { Button, Input, Modal, Row, Form, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
const validateMessages = {
  required: "Username is required!",
  types: {
    email: "Email is not a valid email!",
  },
};
const AddDeveloper = ({
  title,
  open,
  onOk,
  confirmLoading,
  onCancel,
  hideModal,
  fetAgain,
  setfetAgain,
}) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const formData = new FormData();

  const onChangeFIleUpload = ({ file, fileList }) => {
    formData.append("image", file);
  };

  const onFinish = async (values) => {
    console.log(values);
    console.log(values.developer.image.file);
    // Add Developer first

    axiosPrivate
      .post("/developers", {
        name: values.developer.name,
        websiteURL: values.developer.website,
      })
      .then(async (response) => {
        // formData.append("image", values.developer.image.fileList[0]);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        console.log("Form Data");
        console.log(formData);
        axiosPrivate
          .post(`/files/dev/${response.data.data.id}`, formData, config)
          .then((response) => {
            message.success("File Uploaded");
            setfetAgain(fetAgain + 1);
            navigate("/dev", { replace: true });
          })
          .catch((error) => {
            message.error("Error in File Upload ");
          })
          .finally(() => {
            hideModal();
          });
      })
      .catch((error) => {
        message.error("Error in Adding Developers");
      });

    //If image then add image
  };
  return (
    <Modal
      maskClosable={false}
      title={title}
      open={open}
      onOk={onOk}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      footer={null}
      destroyOnClose={true}
    >
      <Row>
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["developer", "name"]}
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={["developer", "website"]}
            rules={[{ type: "url", warningOnly: true }]}
            label="Website"
          >
            <Input />
          </Form.Item>
          <Form.Item name={["developer", "introduction"]} label="Introduction">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name={["developer", "image"]} label="Image">
            <Upload
              maxCount={1}
              listType="picture-card"
              beforeUpload={() => {
                return false;
              }}
              onChange={(file, fileList) => onChangeFIleUpload(file, fileList)}
            >
              {uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              offset: 8,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </Modal>
  );
};
export default AddDeveloper;
