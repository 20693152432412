import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";

const K_MARGIN_TOP = 30;
const K_MARGIN_RIGHT = 30;
const K_MARGIN_BOTTOM = 30;
const K_MARGIN_LEFT = 30;

function Marker() {
  return (
    <>
      <div className="marker"></div>
    </>
  );
}
function SingleGoogleMap({ project }) {
  const [center] = useState({
    lat: parseFloat(project.lattitude),
    lng: parseFloat(project.longitude),
  });
  const [zoomlevel] = useState(14);
  const [markers] = useState([
    { lat: project.lattitude, lng: project.longitude },
  ]);
  console.log({
    lat: project.lattitude,
    lng: project.longitude,
  });
  console.log({
    lat: parseFloat(project.lattitude),
    lng: parseFloat(project.longitude),
  });
  return (
    <div className="locationGoogleMap" style={{ height: "300px" }}>
      <GoogleMapReact
        //   options={{ gestureHandling: "greedy" }}
        bootstrapURLKeys={{
          key: "AIzaSyCDlyW_Vv5txBpw_IMtCtLGPNw4BFRTy1U",
        }}
        center={center}
        zoom={zoomlevel}
        //   onChange={(values) => onBoundChange(values)}
        //   onChildClick={() => onChildClick()}
        margin={[K_MARGIN_TOP, K_MARGIN_RIGHT, K_MARGIN_BOTTOM, K_MARGIN_LEFT]}
        yesIWantToUseGoogleMapApiInternals
      >
        {markers.map((marker, ind) => {
          return <Marker key={ind} lat={marker.lat} lng={marker.lng} />;
        })}
      </GoogleMapReact>
    </div>
  );
}
export default SingleGoogleMap;
