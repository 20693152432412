import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";

function CalVsToronto() {
  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "9%" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="main_blog_post_content">
                <div class="mbp_thumb_post">
                  <h3
                    class="blog_sp_title"
                    style={{ textAlign: "center", color: "green" }}
                  >
                    Calgary Vs Ontario Charts
                  </h3>

                  <div class="thumb" style={{ textAlign: "center" }}>
                    <img
                      class="img-fluid"
                      src="images/stat/cal-vs-ont/image1.jpg"
                      height={500}
                      width={500}
                      alt="bs1.jpg"
                    ></img>
                  </div>
                  <br></br>
                  <div class="thumb" style={{ textAlign: "center" }}>
                    <img
                      class="img-fluid"
                      src="images/stat/cal-vs-ont/image2.jpg"
                      alt="bs1.jpg"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default CalVsToronto;
