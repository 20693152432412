import React, { useEffect } from "react";
import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import "./ten-things.css";
import loadjs from "loadjs";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";

function MortgageCalculator() {
  const { province } = useParams();
  const navigate = useNavigate();

  const handleChange = (value) => {
    navigate(`/mortgage-calc/${value}`, { replace: true });
    window.location.reload();
  };
  useEffect(() => {
    loadjs("./js/script.js", () => {});
    loadjs("https://www.ratehub.ca/assets/js/widget-loader.js");
  }, []);

  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "6%" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="main_blog_post_content">
                <div
                  class="mbp_thumb_post"
                  style={{ textAlign: "center", backgroundColor: "#eaf7ec" }}
                >
                  <div
                    class="details"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <div>
                      {" "}
                      <h2
                        style={{
                          fontFamily: "stagsans,arial,helvetica,sans-serif",
                          fontSize: "1.6em",
                          fontWeight: "400",
                          marginTop: "0px",
                          textDecoration: "none",
                          color: "#A3C139",
                          textAlign: "center",
                        }}
                      >
                        Mortgage payment calculator
                      </h2>
                      <div style={{ textAlign: "center" }}>
                        <Select
                          defaultValue={province}
                          style={{ width: 200 }}
                          size="large"
                          options={[
                            { value: "ON", label: "Ontario" },
                            { value: "AB", label: "Alberta" },
                          ]}
                          onChange={handleChange}
                        ></Select>
                        <br></br>
                        <br></br>
                      </div>
                      <div
                        class="widget"
                        data-widget="calc-payment"
                        data-purchase="only"
                        data-province={province}
                        data-lang="en"
                      ></div>
                      <div
                        // style="text-align:right;"
                        style={{ textAlign: "right" }}
                      >
                        {" "}
                        <a
                          href="https://www.ratehub.ca/"
                          style={{
                            display: "inline",
                            width: "80px",
                            marginTop: ".5em",
                          }}
                        >
                          <img
                            src="https://www.ratehub.ca/assets/images/widget-logo.png"
                            alt="Ratehub.ca logo"
                          ></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default MortgageCalculator;
