import React, { useEffect } from "react";
import Footer from "../../common/footer";
import LoginRegister from "../../common/login-register";
import SidebarMobile from "../../common/sidebar-mobile";
import PlainHeader from "../single-property/PlainHeader";
import "./ten-things.css";
import loadjs from "loadjs";

function WhyInvestCal() {
  useEffect(() => {
    loadjs("./js/script.js", () => {});
  }, []);

  return (
    <>
      <PlainHeader></PlainHeader>

      {/*<!-- Modal -->*/}
      <LoginRegister></LoginRegister>

      {/*<!-- Main Header Nav For Mobile -->*/}
      <SidebarMobile></SidebarMobile>

      {/* <!-- Inner Page Breadcrumb --> */}
      <section
        class="blog_post_container bgc-f7 pb30"
        style={{ paddingTop: "9%" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="main_blog_post_content">
                <div
                  class="mbp_thumb_post"
                  style={{ textAlign: "center", backgroundColor: "#eaf7ec" }}
                >
                  <h3 class="blog_sp_title" style={{ color: "green" }}>
                    Why Invest in Calgary?
                  </h3>
                  <div
                    class="details"
                    style={{
                      textAlign: "left",

                      padding: "20px",
                    }}
                  >
                    <p class="mb30">
                      <h4>
                        In 2022, Calgary was ranked alongside Zürich as the
                        third most livable city in the world, ranking first in
                        Canada and in North America
                      </h4>
                    </p>
                    <p class="mb30">
                      <h4>
                        Statistics Canada has released stats showing{" "}
                        <b>
                          Calgary's Metropolitan Region is one of the
                          fastest-growing areas in Canada.
                        </b>{" "}
                        Calgary's metropolitan area includes Calgary and
                        surrounding communities like Airdrie, Chestermere and
                        Cochrane. The jump in population from July 2021 to July
                        of 2022 was around 49,754
                      </h4>
                    </p>
                    <p class="mb30">
                      <h4>
                        As of September 21, 2022,{" "}
                        <b>
                          Calgary's average sale price is less than half of
                          Toronto's and Vancouver's, and still significantly
                          lower than the sale prices in Ottawa and Montreal.
                        </b>{" "}
                        This makes getting into the market much easier,
                        especially if you want to make a 10-20% down payment
                      </h4>
                    </p>
                    <p class="mb30">
                      <h4>
                        The value of major projects in Calgary in 2022 was $15.3
                        billion, third in the province.
                      </h4>
                    </p>
                    <p class="mb30">
                      <h4>
                        The Calgary Metropolitan Region is home to Canada's
                        second-largest number of corporate head offices among
                        the country's 800 largest corporations
                      </h4>
                    </p>
                    <p class="mb30">
                      <h4>
                        Land transfers in Calgary for 2021 totalled $15.5
                        billion in value, first in the province. The value of
                        land title transfers in Calgary greatly increased 36.3%
                        year-over-year.
                      </h4>
                    </p>
                    <p class="mb30">
                      <h4>
                        2022 has been a banner year for job creation in the
                        Calgary Economic Region (CER), with more than 900,000
                        jobs for the first time in CER history. Employment
                        growth is expected to average 2.1 per cent through 2027
                      </h4>
                    </p>
                    <p class="mb30">
                      <h4>
                        ATB Financial forecasts Alberta's real GDP growth will
                        be around 2.8 per cent this year — likely to be the
                        highest in the country. CIBC's 2023 forecast puts the
                        <b>national growth at 0.6 per cent</b>
                      </h4>
                    </p>
                    <p class="mb30">
                      <h4>
                        The current metro area population of Calgary in 2023 is
                        <b>1,640,000,</b> a <b>1.8%</b> increase from 2022.
                      </h4>
                    </p>
                    <p class="mb30">
                      <h4>External Reference Links</h4>
                      <a
                        href="https://regionaldashboard.alberta.ca/region/calgary/#/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>
                          https://regionaldashboard.alberta.ca/region/calgary/#/
                        </u>
                      </a>
                      <br></br>
                      <a
                        href="https://www.calgary.ca/research/population-profile.html#:~:text=In%202021%2C%20the%20population%20of,cent%20from%20489%2C650%20in%202016."
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>
                          https://www.calgary.ca/research/population-profile.html#:~:text=In%202021%2C%20the%20population%20of,cent%20from%20489%2C650%20in%202016.
                        </u>
                      </a>
                      <br></br>
                      <a
                        href="https://www12.statcan.gc.ca/census-recensement/2021/dp-pd/prof/details/page.cfm?Lang=E&GENDERlist=1,2,3&STATISTIClist=1&HEADERlist=0&DGUIDlist=2021A00054806016&SearchText=calgary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>
                          https://www12.statcan.gc.ca/census-recensement/2021/dp-pd/prof/details/page.cfm?Lang=E&GENDERlist=1,2,3&STATISTIClist=1&HEADERlist=0&DGUIDlist=2021A00054806016&SearchText=calgary
                        </u>
                      </a>
                      <br></br>
                      <a
                        href="https://www.macrotrends.net/cities/20370/calgary/population"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>
                          https://www.macrotrends.net/cities/20370/calgary/population
                        </u>
                      </a>
                      <br></br>
                      <br></br>
                      <p style={{ fontSize: "13px" }}>
                        Last Update: April 2023
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default WhyInvestCal;
